import { MantineThemeOverride } from "@mantine/core";

const theme: MantineThemeOverride = {
  primaryColor: "cyan",
  headings: {
    fontFamily: "Alegreya, serif",
    fontWeight: "500",
  },
  colors: {
    darkRed: [
      "#b71c1c",
      "#b71c1c",
      "#b71c1c",
      "#b71c1c",
      "#b71c1c",
      "#b71c1c",
      "#b71c1c",
      "#b71c1c",
      "#b71c1c",
      "#b71c1c",
    ],
  },

  black: "#3c404a",
};

export default theme;
