import React from "react";

import AuthenticatedRouter from "AuthenticatedRouter";
import ErrorBoundary from "components/ErrorBoundary";
import Layout from "layout";
import { useAuth } from "providers/AuthProvider";
import { BrowserRouter } from "react-router-dom";
import Page404 from "routes/error/Page404";
import Page505 from "routes/error/Page505";
import UnauthenticatedRouter from "UnauthenticatedRouter";

import { SwitchesProvider } from "./providers/SwitchesProvder";

const Router = (): JSX.Element => {
  const { user } = useAuth();

  return (
    <ErrorBoundary errorMessage={<Page404 />}>
      <React.Suspense fallback={<Page505 />}>
        <SwitchesProvider>
          <BrowserRouter>
            <Layout>
              {user ? <AuthenticatedRouter /> : <UnauthenticatedRouter />}
            </Layout>
          </BrowserRouter>
        </SwitchesProvider>
      </React.Suspense>
    </ErrorBoundary>
  );
};

export default Router;
