import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { UserLogin, UserLoginResponse } from "types/generated";
import axiosInstance from "utils/axios-instance";

export default function useLogin(): UseMutationResult<
  AxiosResponse<UserLoginResponse>,
  AxiosError,
  UserLogin
> {
  const queryClient = useQueryClient();

  const mutationFn = (
    payload: UserLogin
  ): Promise<AxiosResponse<UserLoginResponse>> => {
    return axiosInstance.post("/login/", payload);
  };

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
  });
}
