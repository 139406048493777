import { IUser } from "types/api/authTypes";

import useDemographicProfileResults from "./demographic-profile/useDemographicProfileResults";

export default function useDpIntroAnswered(user?: IUser | undefined) {
  const { data: demographicResults, refetch } = useDemographicProfileResults({
    page: 1,
    enabled: !!user,
  });

  const isDemoAnswered =
    demographicResults && demographicResults?.length > 0;

  return { isDemoAnswered, refetch };
}
