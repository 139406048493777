import React from "react";

import {
  BackgroundImage,
  Box,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import ManOnRockBackgroundImage from "assets/backgrounds/man-on-rock.png";
import ContentContainer from "components/ContentContainer";
import CustomMediaQuery from "components/CustomMediaQuery";

const WelcomeBlock = (): JSX.Element => {
  const theme = useMantineTheme();

  return (
    <>
      <BackgroundImage src={ManOnRockBackgroundImage} h={512}>
        <ContentContainer>
          <Box maw={398} py={`calc(${theme.spacing.xl} * 5)`}>
            <CustomMediaQuery smallerThan="xs">
              <Title
                size={40}
                style={{
                  textShadow: "rgb(0 0 0 / 25%) 0px 4px 4px",
                  color: "white",
                }}
              >
                Science-Based Wisdom for a Life Well Lived
              </Title>
            </CustomMediaQuery>
            <CustomMediaQuery largerThan="xs">
              <Title
                order={1}
                style={{
                  textShadow: "rgb(0 0 0 / 25%) 0px 4px 4px",
                  color: "white",
                }}
              >
                Science-Based Wisdom for a Life Well Lived
              </Title>
            </CustomMediaQuery>

            <Text
              mt="md"
              size="lg"
              style={{
                textShadow: "rgb(0 0 0 / 25%) 0px 4px 4px",
                color: "white",
              }}
            >
              Understand and practice the most valuable skills for living a
              joyful, meaningful life.
            </Text>
          </Box>
        </ContentContainer>
      </BackgroundImage>
    </>
  );
};

export default WelcomeBlock;
