import { useEffect, useState } from "react";

import useConnectedFactors from "hooks/factors/useConnectedFactors";
import useFactor from "hooks/factors/useFactor";
import useFactorAssessmentHistory from "hooks/factors/useFactorAssessmentHistory";
import useFactorProgress from "hooks/factors/useFactorProgress";
import { useAuth } from "providers/AuthProvider";
import { IFactorAssessmentHistory } from "types/api/factorTypes";

const useFactorData = (factorId: string): any => {
  const { user } = useAuth();
  const isAuthenticated = !!user;

  const { data: factorData } = useFactor(factorId);

  const { data: connectedFactorsData } = useConnectedFactors(factorId);
  const { data: assessmentHistoryData } = useFactorAssessmentHistory({
    factorId,
    enabled: isAuthenticated,
  });
  const { data: progressData } = useFactorProgress(factorId);

  const [sortedAssessmentHistory, setSortedAssessmentHistory] = useState<
    IFactorAssessmentHistory[]
  >([]);

  useEffect(() => {
    if (assessmentHistoryData) {
      const sorted = [...assessmentHistoryData].sort(
        (a, b) => (a.is_gpa ? 1 : 0) - (b.is_gpa ? 1 : 0)
      );
      setSortedAssessmentHistory(sorted);
    }
  }, [assessmentHistoryData]);

  return {
    isAuthenticated,
    currentData: factorData,
    connectedFactors: connectedFactorsData,
    sortedAssessmentHistory,
    progressData: progressData,
  };
};

export default useFactorData;
