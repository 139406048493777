import React, { useCallback, useEffect } from "react";

import {
  Badge,
  Checkbox,
  Group,
  Image,
  Radio,
  Stack,
  Text,
} from "@mantine/core";
import {
  EmblaCarousel,
  EmblaSlide,
  IEmblaCarouselProps,
} from "components/EmblaCarousel";
import { useParams } from "react-router-dom";
import {
  IAssessmentQuestion,
  IPaginatedAssessmentQuestions,
} from "types/api/assessmentTypes";
import { InputType, OptionGroupName } from "types/inputTypes";
import FivePointSelect from "UI/FivePointSelect";
import LinkerSelect from "UI/LinkerSelect";

export type AssessmentCarouselValue = { [key: number]: number | number[] };

const AssessmentCarousel = ({
  emblaRef,
  emblaApi,
  ActionButton,
  data,
  value,
  onChange,
  isModalOpen,
}: Omit<IEmblaCarouselProps, "children"> & {
  data: IPaginatedAssessmentQuestions;
  value: AssessmentCarouselValue;
  onChange: (id: number, value: number | number[]) => void;
  isModalOpen?: boolean;
}): JSX.Element => {
  // This component is intended only for assessment pages
  const { page } = useParams();

  const getInputFromQuestion = useCallback(
    (question: IAssessmentQuestion) => {
      const savedAnswer = value[question.id];

      const handleChange = (v: string | string[]): void => {
        const selectedValue = Array.isArray(v)
          ? v.map((stringNumber) => Number(stringNumber))
          : Number(v);
        onChange(question.id, selectedValue);
      };

      if (
        question.input_type === InputType.CHECKBOX ||
        question.input_type === InputType.MULTISELECT
      ) {
        const checkboxValue = Array.isArray(savedAnswer)
          ? savedAnswer.map((a) => String(a))
          : undefined;

        return (
          <Checkbox.Group
            value={checkboxValue}
            onChange={handleChange}
            size="md"
          >
            {question.option_group.option_choices.map(({ id, name }) => (
              <Checkbox key={id} value={`${id}`} label={name} />
            ))}
          </Checkbox.Group>
        );
      }

      if (question.input_type === InputType.STATEMENT) {
        return <></>;
      }

      if (question.option_group.name === OptionGroupName.LINKERT) {
        return (
          <LinkerSelect
            value={savedAnswer ? String(savedAnswer) : undefined}
            onChange={(_value) => onChange(question.id, Number(_value))}
            options={question.option_group.option_choices.map(
              ({ id, name }) => ({ value: `${id}`, label: name })
            )}
          />
        );
      }
      if (
        question.option_group.name === OptionGroupName.LEVELS_OF_CONSIDERATION
      ) {
        return (
          <FivePointSelect
            value={savedAnswer ? String(savedAnswer) : undefined}
            onChange={(_value) => onChange(question.id, Number(_value))}
            options={question.option_group.option_choices.map(
              ({ id, name }) => ({ value: `${id}`, label: name })
            )}
          />
        );
      }

      return (
        <Radio.Group
          value={savedAnswer ? String(savedAnswer) : undefined}
          onChange={(_value) => onChange(question.id, Number(_value))}
          size="md"
        >
          {question.option_group.option_choices.map(({ id, name }) => (
            <Radio key={id} value={`${id}`} label={name} />
          ))}
        </Radio.Group>
      );
    },
    [onChange, value]
  );

  const calculateItemNumber = (index: number) => {
      const currentPage = Number(page);
      const itemsPerPage = 10;

      // Calculate the item number based on the current page and index
      // This assumes that the index is within the current page
      return (currentPage - 1) * itemsPerPage + index + 1;
  };

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (emblaApi && !isModalOpen) {
        const currentQuestionIndex = emblaApi.selectedScrollSnap() || 0;
        const currentQuestion = data.results[currentQuestionIndex];
        const { code } = event;

        if (code >= "Digit1" && code <= "Digit7" && currentQuestion) {
          const optionIndex = parseInt(code.replace("Digit", ""), 10) - 1;
          const option =
            currentQuestion.option_group.option_choices[optionIndex];

          if (option) {
            onChange(currentQuestion.id, option.id);
          }
        }
      }
    },
    [data.results, emblaApi, onChange]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <EmblaCarousel
      emblaRef={emblaRef}
      emblaApi={emblaApi}
      ActionButton={ActionButton}
    >
      {data.results.map((question: IAssessmentQuestion, index) => (
        <EmblaSlide key={question.id}>
          <Group justify="apart">
            <Badge size="lg" variant="filled" radius="md">
              {calculateItemNumber(index)}
            </Badge>
            {question.image && (
              <Image
                src={question.image}
                alt="Question Illustration"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            )}
          </Group>
          <Stack align="center" justify="center" h="100%">
            <Text
              fw={700}
              size="lg"
              style={{
                align:
                  question.input_type === InputType.STATEMENT
                    ? "center"
                    : "left",
              }}
            >
              {question.text}
            </Text>
            {getInputFromQuestion(question)}
          </Stack>
        </EmblaSlide>
      ))}
    </EmblaCarousel>
  );
};

export default AssessmentCarousel;
