import React from "react";

import { TextInput as MTextInput, TextInputProps } from "@mantine/core";

const TextInput = (props: TextInputProps): JSX.Element => (
  <MTextInput
    {...props}
    styles={(theme) => ({
      input: {
        borderRadius: 0,
        borderColor: theme.colors.gray[5],
        "&:focus, &:focus-within": {
          borderColor: theme.colors.gray[6],
        },
      },
    })}
  />
);

export default TextInput;
