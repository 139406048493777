export enum Membership {
  UNPAID = "UNPAID",
  STANDARD = "STANDARD",
  YEAR = "YEARLY",
  LIFE = "LIFETIME",
}

export type MembershipType =
  | Membership.UNPAID
  | Membership.STANDARD
  | Membership.YEAR
  | Membership.LIFE;
