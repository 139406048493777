import React from "react";

import { Anchor, Stack, Text } from "@mantine/core";

const AboutAssessmentCenter = (): JSX.Element => (
  <Stack>
    <Text>
      The Assessment Center web app project is a parallel, personalized
      companion to AMeaningOfLife.org. With considerable depth and breadth, A
      Meaning Of Life offers the science and art around optimizing well-being
      with thorough explanations, resources, and how-to’s.
    </Text>
    <Text>
      The site includes hundreds of pages of curated, science-based wisdom for
      living well, presenting concepts and frameworks that are easy to
      understand, and emphasizing deep elements like meaning and purpose as a
      source of optimal joy.
    </Text>
    <Text span>
      Learn more about AMeaningOfLife.org{" "}
      <Anchor href="https://ameaningoflife.org/about/" color="cyan">
        here.
      </Anchor>
    </Text>
  </Stack>
);

export default AboutAssessmentCenter;
