import React from "react";

import {
  Badge,
  Button,
  Grid,
  Group,
  Image,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import BackButton from "components/BackButton";
import MinutesBadge from "components/badges/MinutesBadge";
import QuestionsBadge from "components/badges/QuestionsBadge";
import CustomMediaQuery from "components/CustomMediaQuery";
import FactorGradeResult from "modules/factors/components/FactorGradeResult";
import {
  FactorCategoryTitle,
  FactorGrade,
  FactorGradeTitle,
} from "modules/factors/utils/enums";
import { useAuth } from "providers/AuthProvider";
import { useNavigate, useParams } from "react-router-dom";
import { IFactor } from "types/api/factorTypes";
import { IAssessmentProgress } from "types/api/question";

const getImageUrlByName = (name: string): string =>
  `https://gpa-factors.s3.us-west-2.amazonaws.com/images/${name.toLowerCase()}.jpg`;

const MainBlock = ({
  data,
  progressData,
}: {
  data: IFactor;
  progressData?: IAssessmentProgress;
}): JSX.Element => {
  const { factorId } = useParams();
  const navigate = useNavigate();

  const { user } = useAuth();
  const isPaid = user?.is_paid;

  const hasAssessmentBeenTaken =
    !user ||
    (!!data &&
      (!data.grade ||
        [
          FactorGrade.NOT_TAKEN,
          FactorGrade.GPA_GROWTH_ZONES,
          FactorGrade.GPA_CAPABILITIES,
          FactorGrade.GPA_STRENGTHS,
        ].includes(data.grade)));

  const handleTakeAssessment = (): void => {
    let route = "/";

    if (!user) {
      route = "/login";
    } else {
      route = `/factors/${factorId}/assessment/${1}`;

      if (!isPaid) {
        route = "/select-subscription";
      }
    }

    navigate(route);
  };

  const handleLearnAndPractice = (): void => {
    if (data.read_more_link) {
      window.open(data.read_more_link, "_blank");
    }
  };

  return (
    <Paper radius="md" withBorder p="lg">
      <Grid columns={4} gutter="lg" align="stretch">
        <Grid.Col span={{ base: 1, sm: 1, xs: 4 }}>
          <CustomMediaQuery largerThan="sm">
            <div>
              <Group justify="space-between">
                <BackButton to="/" />
                <Stack>
                  <FactorGradeResult
                    title={
                      FactorGradeTitle[data.grade || FactorGrade.NOT_TAKEN]
                    }
                    fullWidth={false}
                    size="lg"
                  />
                </Stack>
              </Group>
              <Stack gap="xs" mb="xl">
                <Title>{data.name}</Title>
                <Group>
                  <Badge color="teal">
                    {FactorCategoryTitle[data.factor_category]} category
                  </Badge>
                  <QuestionsBadge label={`${data.questions_count} questions`} />
                  <MinutesBadge value={data.estimated_time / 60} />
                </Group>
              </Stack>
            </div>
          </CustomMediaQuery>
          <Image
            height={360}
            radius="md"
            src={getImageUrlByName(data.image)}
            alt={data.name}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 3, sm: 3, xs: 4 }}>
          <Stack justify="space-between" h="100%">
            <CustomMediaQuery smallerThan="sm">
              <div>
                <Group justify="space-between">
                  <BackButton to="/" />
                  <Stack>
                    <FactorGradeResult
                      title={
                        FactorGradeTitle[data.grade || FactorGrade.NOT_TAKEN]
                      }
                      fullWidth={false}
                      size="lg"
                    />
                  </Stack>
                </Group>
                <Stack gap="xs" mt="lg">
                  <Title>{data.name}</Title>
                  <Group>
                    <Badge color="teal">
                      {FactorCategoryTitle[data.factor_category]} category
                    </Badge>
                    <QuestionsBadge
                      label={`${data.questions_count} questions`}
                    />
                    <MinutesBadge value={data.estimated_time / 60} />
                  </Group>
                </Stack>
              </div>
            </CustomMediaQuery>

            {data.question && (
              <Text mt="md" fs="italic" fw={500}>
                {data.question}
              </Text>
            )}
            <Text mt="md" size="lg" span>
              {data.description}
            </Text>
            {progressData && progressData.questions_count <= 5 && (
              <Text>
                Note: this factor assessment is only 5 questions for now. It’s
                that simple to test this one. Taking the assessment here won’t
                give you more granular results than the bulk Growth Profile
                Assessment; just more recent results.
              </Text>
            )}

            {data.hint && <Text>{data.hint}</Text>}
            <Group>
              <Button radius="xs" size="md" onClick={handleTakeAssessment}>
                {hasAssessmentBeenTaken
                  ? "Take Assessment"
                  : "Retake Assessment"}
              </Button>
              <Button
                variant="light"
                radius="xs"
                size="sm"
                onClick={handleLearnAndPractice}
              >
                Learn and Practice
              </Button>
            </Group>
          </Stack>
        </Grid.Col>
      </Grid>
    </Paper>
  );
};

export default MainBlock;
