import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import axiosInstance from "utils/axios-instance";
import {IForgotPasswordRequest} from "types/api/authTypes";

export default function useForgotPassword(): UseMutationResult<
  AxiosResponse<undefined>,
  AxiosError,
  IForgotPasswordRequest
> {
  const queryClient = useQueryClient();

  const mutationFn = (
    payload: IForgotPasswordRequest
  ): Promise<AxiosResponse<undefined>> => {
    return axiosInstance.post("/users/forgot-password", payload);
  };

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
  });
}
