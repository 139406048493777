import React, { useMemo } from "react";

import useBem from "hooks/useBem";
import { useLocation } from "react-router-dom";

import DemographicForm from "./modules/DemographicForm";
import Header from "./modules/Header";

import "./Layout.scss";
import LoadingOverlay from "../components/LoadingOverlay";
import { useAuth } from "../providers/AuthProvider";

const Layout = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { bemBlock } = useBem("Layout");
  const { pathname } = useLocation();

  const { isInitialized } = useAuth();

  const headerHiddenPaths = [
    "/login",
    "/register",
    "/forgot-password",
    "/wp-auth",
    "/reset-password",
  ];
  const isHeaderHidden = useMemo(
    () => headerHiddenPaths.some((path) => pathname.startsWith(path)),
    [pathname]
  );

  return (
    <div className={bemBlock()}>
      <LoadingOverlay visible={!isInitialized} />

      {!isHeaderHidden && <Header />}
      {children}
      <DemographicForm />
    </div>
  );
};

export default Layout;
