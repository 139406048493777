import React, { useState } from "react";

import { Button, Checkbox, Group, Radio, useMantineTheme } from "@mantine/core";
import ContentContainer from "components/ContentContainer";
import CustomMediaQuery from "components/CustomMediaQuery";
import { useAuth } from "providers/AuthProvider";
import { Navigate, useSearchParams } from "react-router-dom";
import { Membership, MembershipType } from "types/api/paymentTypes";
import { showErrorNotification } from "utils/notifications";
import useGetSubscriptionPaymentUrl from "hooks/payment/useGetSubscriptionPaymentUrl";

const SelectSubscriptionPage = (): JSX.Element => {
  const theme = useMantineTheme();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect_uri");

  const { user } = useAuth();
  const isPaid = user?.is_paid;

  const getSubscriptionPaymentUrlMutation = useGetSubscriptionPaymentUrl()

  const [subscriptionPlan, setSubscriptionPlan] = useState<MembershipType>();
  const [accepted, setAccepted] = useState<boolean>(false);

  const handleClick = (): void => {
    if (subscriptionPlan) {
      getSubscriptionPaymentUrlMutation.mutate({
        plan: subscriptionPlan,
        redirectUrl: redirectUrl || undefined,
      }, {
        onError: () => {
          showErrorNotification("Something went wrong", "Try again later")
        },
        onSuccess: (subscriptionPaymentUrl) => {
          window.location.replace(subscriptionPaymentUrl)
        }
      })
    }
  };

  return isPaid ? (
    <Navigate to="/" replace />
  ) : (
    <ContentContainer py={`calc(${theme.spacing.xl} * 6)`}>
      <Group justify="center">
        <Radio.Group
          name="favoriteFramework"
          label="Become a member"
          description="Select a subscription plan"
          size="xl"
          withAsterisk={false}
          onChange={(value: string) =>
            setSubscriptionPlan(value as MembershipType)
          }
        >
          <Group mt={`calc(${theme.spacing.xl} * 2)`}>
            <Radio
              value={Membership.STANDARD}
              label="Standard"
              description="$10 for a month subscription"
            />
            <Radio
              value={Membership.YEAR}
              label="Yearly"
              description="$100 for year subscription"
            />
            <Radio
              value={Membership.LIFE}
              label="Lifetime"
              description="$250 for lifetime subscription"
            />
          </Group>
        </Radio.Group>
      </Group>
      <Group justify="center">
        <Checkbox
          label="I understand this website and app are still in development.
            I have verified the content I want is currently available before subscribing."
          checked={accepted}
          onChange={(event) => setAccepted(event.currentTarget.checked)}
          style={{ width: "100%", maxWidth: "620px", marginTop: "40px" }}
        />
      </Group>
      <CustomMediaQuery smallerThan="xs">
        <Group justify="center" mt={`calc(${theme.spacing.xl} * 2)`}>
          <Button
            size="md"
            onClick={handleClick}
            loading={getSubscriptionPaymentUrlMutation.isPending}
            disabled={!subscriptionPlan || !accepted}
          >
            Proceed to Checkout
          </Button>
        </Group>
      </CustomMediaQuery>
      <CustomMediaQuery largerThan="xs">
        <Button
          mt="xl"
          size="md"
          fullWidth
          onClick={handleClick}
          loading={getSubscriptionPaymentUrlMutation.isPending}
          disabled={!subscriptionPlan || !accepted}
        >
          Proceed to Checkout
        </Button>
      </CustomMediaQuery>
    </ContentContainer>
  );
};

export default SelectSubscriptionPage;
