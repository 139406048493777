import React from "react";

import { Button } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons";
import { Link, useNavigate } from "react-router-dom";

const BackButton = ({
  to,
  replace = false,
  label = "Back to Home",
}: {
  label?: string;
  to?: string;
  replace?: boolean;
}): JSX.Element => {
  const navigate = useNavigate();

  if (!to) {
    return (
      <Button
        variant="default"
        leftSection={<IconArrowLeft size={14} />}
        onClick={() => navigate(-1)}
      >
        {label}
      </Button>
    );
  }

  return (
    <Button
      component={Link}
      to={to}
      variant="default"
      leftSection={<IconArrowLeft size={14} />}
      replace={replace}
    >
      {label}
    </Button>
  );
};

export default BackButton;
