import React, { useCallback, useEffect, useState } from "react";

import { Button, Group, Paper } from "@mantine/core";
import { IconChevronDown, IconChevronUp } from "@tabler/icons";
import ContentContainer from "components/ContentContainer";

import { UseEmblaCarouselType } from "embla-carousel-react";
import useBem from "hooks/useBem";

import "./EmblaCarousel.scss";
import CustomMediaQuery from "../CustomMediaQuery";

export interface IEmblaCarouselProps {
  emblaRef: UseEmblaCarouselType[0];
  emblaApi: UseEmblaCarouselType[1];
  children: React.ReactNode;
  ActionButton?: JSX.Element;
}

const EmblaCarousel = ({
  emblaRef,
  emblaApi,
  children,
  ActionButton,
}: IEmblaCarouselProps): JSX.Element => {
  const { bemBlock, bemElement } = useBem("EmblaCarousel");

  const [scrollProgress, setScrollProgress] = useState(0);

  const isScrollPrevDisabled =
    !emblaApi || (emblaApi && !emblaApi.canScrollPrev());
  const isScrollNextDisabled =
    !emblaApi || (emblaApi && !emblaApi.canScrollNext());

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );

  const onScroll = useCallback(() => {
    if (!emblaApi) return;
    const progress = Math.max(0, Math.min(1, emblaApi.scrollProgress()));
    setScrollProgress(progress * 100);
  }, [emblaApi, setScrollProgress]);

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.on("scroll", onScroll);
    emblaApi.on("reInit", onScroll);
  }, [emblaApi, onScroll]);

  return (
    <ContentContainer>
      <Paper>
        <div className={bemBlock()}>
          <div className={bemElement("viewport")} ref={emblaRef}>
            <div className={bemElement("container")}>{children}</div>
          </div>

          <Group justify="space-between">
            <Button.Group>
              <Button
                size="sm"
                variant="light"
                onClick={scrollPrev}
                disabled={isScrollPrevDisabled}
              >
                <IconChevronUp size={26} />
              </Button>
              <Button
                size="sm"
                variant="light"
                onClick={scrollNext}
                disabled={isScrollNextDisabled}
              >
                <IconChevronDown size={26} />
              </Button>
            </Button.Group>
            {ActionButton}
          </Group>

          <CustomMediaQuery smallerThan="sm">
            <div className={bemElement("progress")}>
              <div
                className={bemElement("progress-bar")}
                style={{ transform: `translateX(${scrollProgress}%)` }}
              />
            </div>
          </CustomMediaQuery>
        </div>
      </Paper>
    </ContentContainer>
  );
};

export default EmblaCarousel;
