import React, { useCallback } from "react";

import { Anchor, Button, Group, Tooltip } from "@mantine/core";
import AssessmentCarousel, {
  AssessmentCarouselValue,
} from "components/assessment/AssessmentCarousel";
import { IEmblaCarouselProps } from "components/EmblaCarousel";
import useSaveGpaAnswers from "hooks/gpa/useSaveGpaAnswers";
import useSubmitGpaAssessment from "hooks/gpa/useSubmitGpaAssessment";
import { useSwitches } from "providers/SwitchesProvder";
import { useNavigate, useParams } from "react-router-dom";
import { IPaginatedAssessmentQuestions } from "types/api/assessmentTypes";
import { IAssessmentProgress } from "types/api/question";
import { isDev } from "utils/constants";
import {
  showErrorNotification,
  showSuccessNotification,
} from "utils/notifications";

const GpaAssessmentCarousel = ({
  emblaRef,
  emblaApi,
  questionsData,
  value,
  setValue,
  progressData,
  isModalOpen,
}: {
  emblaRef: IEmblaCarouselProps["emblaRef"];
  emblaApi: IEmblaCarouselProps["emblaApi"];
  questionsData: IPaginatedAssessmentQuestions;
  value: AssessmentCarouselValue;
  setValue: React.Dispatch<React.SetStateAction<AssessmentCarouselValue>>;
  progressData: IAssessmentProgress;
  isModalOpen: boolean;
}): JSX.Element => {
  const { page } = useParams();
  const navigate = useNavigate();
  const { isTesting } = useSwitches();

  const valueCount = Object.keys(value).length;
  const totalQuestions = questionsData.results.length;

  const saveGpaAnswersMutation = useSaveGpaAnswers();
  const submitGpaAssessmentMutation = useSubmitGpaAssessment();

  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );

  const handleSelect = (id: number, _value: number | number[]): void => {
    setValue((prev) => ({ ...prev, [id]: _value }));
    if (emblaApi && emblaApi.canScrollNext()) {
      scrollNext();
    }
  };

  const isSubmitDisabled = !(
    progressData.questions_count === progressData.answered_count
  );
  const totalPage = Math.ceil(questionsData.count / 10);
  const isLastPage = Number(page) === totalPage;
  const isNextDisabled = valueCount < totalQuestions || isLastPage;

  const handleSubmit = async (): Promise<void> => {
    saveGpaAnswersMutation.mutate(value, {
      onError: () => {
        showErrorNotification("Submit is failed!");
      },
      onSuccess: () => {
        submitGpaAssessmentMutation.mutate(undefined, {
          onError: () => {
            showErrorNotification("Submit is failed!");
          },
          onSuccess: () => {
            showSuccessNotification("Submit is successful!");
            window.location.replace("/");
          },
        });
      },
    });
  };

  const handleSaveAnswers = (answers: AssessmentCarouselValue): void => {
    saveGpaAnswersMutation.mutate(answers, {
      onError: () => {
        showErrorNotification("Answers save is failed");
      },
    });
  };

  const handleTestFill = useCallback(async () => {
    const testAnswers: AssessmentCarouselValue = {};
    questionsData.results.forEach((question) => {
      const answerId = question.option_group.option_choices[0].id || 1;
      testAnswers[question.id] = answerId;
    });
    await handleSaveAnswers(testAnswers);

    if (emblaApi) {
      emblaApi.scrollTo(0);
    }
    navigate(`/gpa-assessment/${Number(page) + 1}`);
  }, [emblaApi, handleSaveAnswers, navigate, value]);

  const handleNextPage = useCallback(async () => {
    const isAllQuestionsAnswered =
      Object.keys(value).length >= questionsData.results.length;

    const answeredQuestions = Object.keys(value).map(Number);
    const firstUnansweredQuestionIndex = questionsData.results.findIndex(
      (question) => !answeredQuestions.includes(question.id)
    );

    if (!isAllQuestionsAnswered) {
      showErrorNotification(
        "Please answer all questions before proceeding to the next page."
      );
      if (emblaApi) {
        emblaApi.scrollTo(firstUnansweredQuestionIndex);
      }
      return;
    }
    if (!isNextDisabled) {
      if (valueCount > 0) {
        await handleSaveAnswers(value);
      }

      if (emblaApi) {
        emblaApi.scrollTo(0);
      }
      navigate(`/gpa-assessment/${Number(page) + 1}`);
    }
  }, [
    emblaApi,
    handleSaveAnswers,
    navigate,
    value,
    questionsData.results.length,
  ]);

  const ActionButtons = (
    <Group>
      <Anchor onClick={handleNextPage}>
        <Button disabled={isNextDisabled}>Next Page</Button>
      </Anchor>
      <Tooltip
        label={`To submit you need to give an answer to ${progressData.questions_count} questions`}
        disabled={!isSubmitDisabled}
      >
        <Group>
          <Button
            variant="light"
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
            loading={submitGpaAssessmentMutation.isPending}
          >
            Submit
          </Button>
        </Group>
      </Tooltip>
    </Group>
  );

  return (
    <div>
      <AssessmentCarousel
        emblaRef={emblaRef}
        emblaApi={emblaApi}
        data={questionsData}
        value={value}
        onChange={handleSelect}
        ActionButton={ActionButtons}
        isModalOpen={isModalOpen}
      />
      {isDev && (
        <Group px="xl" justify="right">
          {isTesting && (
            <Button variant="outline" onClick={handleTestFill}>
              Fill Test Answers
            </Button>
          )}
        </Group>
      )}
    </div>
  );
};

export default GpaAssessmentCarousel;
