import React from "react";

import { AxiosResponse } from "axios";
import useLogin from "hooks/auth/useLogin";
import useMe from "hooks/auth/useMe";
import useRegister from "hooks/auth/useRegister";
import {
  ISessionAuthTokens,
  IUser,
  LoginWithFacebookRequest,
  LoginWithGoogleRequest,
} from "types/api/authTypes";
import axiosInstance from "utils/axios-instance";
import { updateViewedDp } from "utils/functions";
import { showErrorNotification } from "utils/notifications";
import TokenHelper from "utils/tokenHelper";

export const AuthContext = React.createContext({});

function AuthProvider({ children }: { children: React.ReactNode }): any {
  const loginMutation = useLogin();
  const registerMutation = useRegister();

  const tokens = TokenHelper.get();

  const { data: userData, isLoading: isUserLoading, refetch: refetchUser } = useMe(
    tokens.accessToken
  );

  const refresh = async () => {
    await refetchUser();
  }

  const login = async (payload: {
    email: string;
    password: string;
  }): Promise<ISessionAuthTokens | void> => {
    return new Promise((resolve, reject) => {
      loginMutation.mutate(payload, {
        onError: async (e) => {
          TokenHelper.clear();
          showErrorNotification("Error", "Authentication failed");
          reject(e); // Reject the promise with the error
        },
        onSuccess: async (response) => {
          const authTokens = {
            accessToken: response.data.access,
            csrfToken: response.data.csrf,
          };
          TokenHelper.update(authTokens);
          resolve(authTokens)
        },
      })

    });
  };

  const register = (payload: {
    email: string;
    first_name: string;
    last_name: string;
    password: string;
  }): Promise<ISessionAuthTokens | void> => {
    return new Promise((resolve, reject) => {
      registerMutation.mutate(payload, {
        onError: async (e) => {
          await TokenHelper.clear();
          showErrorNotification("Error", JSON.stringify(e?.response?.data));
          reject(e); // Reject the promise with the error
        },
        onSuccess: async (response) => {
          const authTokens = {
            accessToken: response.data.access,
            csrfToken: response.data.csrf,
          };
          TokenHelper.update(authTokens);
          resolve(authTokens); // Resolve the promise with the auth tokens
        },
      });
    });
  };

  const loginWithGoogle = async (
    payload: LoginWithGoogleRequest
  ): Promise<ISessionAuthTokens | void> => {
    return axiosInstance
      .post("/social-auth/google", payload)
      .then(({ data }) => {
        const authTokens = { accessToken: data.access, csrfToken: data.csrf };
        TokenHelper.update(authTokens);
        return authTokens;
      })
      .catch(() =>
        showErrorNotification("Error", "Authentication with google is failed")
      );
  };

  const loginWithFacebook = async (
    payload: LoginWithFacebookRequest
  ): Promise<ISessionAuthTokens | void> => {
    return axiosInstance
      .post("/social-auth/facebook", payload)
      .then(({ data }) => {
        const authTokens = { accessToken: data.access, csrfToken: data.csrf };
        TokenHelper.update(authTokens);
        return authTokens;
      })
      .catch(() =>
        showErrorNotification("Error", "Authentication with google is failed")
      );
  };

  const logout = (): Promise<void> => {
    return axiosInstance
      .post("/logout/", undefined, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": tokens.csrfToken,
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      })
      .then(() => {
        TokenHelper.clear();
        updateViewedDp(false);
        window.location.replace("/");
      })
      .catch(() => {
        showErrorNotification("Failed to log out");
      });
  };

  const getCurrentUser = (): Promise<AxiosResponse<IUser>> => {
    return axiosInstance.get("/current/", {
      headers: {
        Authorization: `Bearer ${tokens.accessToken}`,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        isInitialized: !isUserLoading,
        user: userData,
        login,
        logout,
        register,
        refresh,
        getCurrentUser,
        loginWithGoogle,
        loginWithFacebook,
        isLoginLoading: loginMutation.isPending,
        isRegisterLoading: registerMutation.isPending,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth(): any {
  const context: any = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
