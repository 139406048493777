import React from "react";

import { Button, ButtonProps } from "@mantine/core";

const PrimaryButton = ({
  children,
  ...buttonProps
}: ButtonProps & any): JSX.Element => {
  return (
    <Button {...buttonProps} type="button" radius={0} color="dark.4">
      {children}
    </Button>
  );
};

export default PrimaryButton;
