import React from "react";

import { Anchor, List, Stack, Text, ThemeIcon } from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons";

const DataProcessing = (): JSX.Element => (
  <Stack>
    <Text>
      Data that you provide will contribute to academic research on well-being,
      helping scientists uncover the keys to better living, eventually helping
      others to live better lives themselves.
    </Text>
    <List
      spacing="xs"
      size="md"
      center
      icon={
        <ThemeIcon color="teal" size={16} radius="xl">
          <IconCircleCheck size={16} />
        </ThemeIcon>
      }
    >
      <List.Item>
        Your data is anonymized and not identifiable with you as an individual.
      </List.Item>
      <List.Item>
        The data is never shared for monetary gain or marketing purposes.
      </List.Item>
    </List>
    <Text span>
      You can learn more by checking out our{" "}
      <Anchor
        href="https://ameaningoflife.org/terms-of-service/"
        target="_blank"
      >
        Terms of Service
      </Anchor>{" "}
      and{" "}
      <Anchor href="https://ameaningoflife.org/privacy-policy/" target="_blank">
        Privacy Policy
      </Anchor>
    </Text>
  </Stack>
);

export default DataProcessing;
