import React from "react";

import { Badge, BadgeProps } from "@mantine/core";

import { FactorGrade, FactorGradeTitle } from "../utils/enums";

const FactorGradeResult = ({
  title,
  onClick,
  ...badgeProps
}: BadgeProps & {
  title: string;
  onClick?(): void;
}): JSX.Element => {
  let color = "gray";

  const isDangerColor = [
    FactorGradeTitle[FactorGrade.GROWTH_ZONES],
    FactorGradeTitle[FactorGrade.GPA_GROWTH_ZONES],
  ].includes(title);
  if (isDangerColor) {
    color = "red";
  }

  const isWarningColor = [
    FactorGradeTitle[FactorGrade.CAPABILITIES],
    FactorGradeTitle[FactorGrade.GPA_CAPABILITIES],
  ].includes(title);
  if (isWarningColor) {
    color = "orange";
  }

  const isSuccessColor = [
    FactorGradeTitle[FactorGrade.STRENGTHS],
    FactorGradeTitle[FactorGrade.GPA_STRENGTHS],
    FactorGradeTitle[FactorGrade.TAKEN],
  ].includes(title);
  if (isSuccessColor) {
    color = "green";
  }

  return (
    <Badge
      size="md"
      variant="light"
      radius="xs"
      fullWidth
      {...badgeProps}
      onClick={onClick}
      color={color}
    >
      {title}
    </Badge>
  );
};

export default FactorGradeResult;
