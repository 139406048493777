import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { IUser } from "types/api/authTypes";
import axiosInstance, { authorizedAxiosInstance } from "utils/axios-instance";
import TokenHelper from "utils/tokenHelper";

export default function useMe(
  accessToken: string | null
): UseQueryResult<IUser, AxiosError> {
  const queryFn = async (): Promise<AxiosResponse<IUser>> => {
    if (accessToken) {
      const authTokens = await axiosInstance
        .post("/refresh-token/")
        .catch(() => {
          TokenHelper.clear();
        });

      TokenHelper.update({
        accessToken: authTokens?.data.access,
        csrfToken: authTokens?.data.csrf,
      });

      authorizedAxiosInstance.defaults.headers.Authorization = `Bearer ${authTokens?.data.access}`;
    }

    return authorizedAxiosInstance.get("/current/");
  };

  return useQuery({
    queryKey: ["me"],
    queryFn,
    enabled: !!accessToken,
  });
}
