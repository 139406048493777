interface AuthenticationTokens {
  accessToken: string;
  csrfToken: string;
}

class TokenHelper {
  private static key = "auth";

  public static hasTokens(): boolean {
    return !!sessionStorage.getItem(this.key);
  }

  public static get = (): AuthenticationTokens => {
    const token = sessionStorage.getItem(this.key);
    const initialTokens = {
      accessToken: "",
      csrfToken: "",
    };

    if (token) {
      try {
        const { accessToken, csrfToken }: AuthenticationTokens =
          JSON.parse(token);
        return { accessToken, csrfToken };
      } catch (e) {
        sessionStorage.removeItem(this.key);
        return initialTokens;
      }
    }
    return initialTokens;
  };

  public static update = ({
    accessToken,
    csrfToken,
  }: AuthenticationTokens): void => {
    sessionStorage.setItem(
      this.key,
      JSON.stringify({ accessToken, csrfToken })
    );
  };

  public static clear(): void {
    sessionStorage.removeItem(this.key);
  }
}

export default TokenHelper;
