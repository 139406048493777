import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { IPaginatedAssessmentQuestions } from "types/api/assessmentTypes";
import { authorizedAxiosInstance } from "utils/axios-instance";
import { AxiosError } from "axios";

export default function useFactorQuestions({
  factorId,
  page,
  page_size = "10",
}: {
  factorId: string;
  page: string;
  page_size?: string;
}): UseQueryResult<IPaginatedAssessmentQuestions, AxiosError> {
  const queryFn = (): Promise<IPaginatedAssessmentQuestions> => {
    const params = {
      page,
      page_size,
    };

    return authorizedAxiosInstance.get(`/factors/${factorId}/questions`, {
      params,
    });
  };

  return useQuery({
    queryFn,
    queryKey: ["factorQuestions", factorId, page, page_size],
  });
}
