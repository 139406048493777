import React from "react";

import { Route, Routes } from "react-router-dom";
import Page404 from "routes/error/Page404";
import AboutPage from "routes/public/AboutPage/AboutPage";
import ForgotPasswordPage from "routes/public/auth/ForgotPasswordPage";
import ResetPasswordPage from "routes/public/auth/ResetPasswordPage";
import SignInPage from "routes/public/auth/SignInPage";
import SignUpPage from "routes/public/auth/SignUpPage";
import FactorPage from "routes/public/FactorPage";
import HomePage from "routes/public/HomePage";

import SunburstChartPage from "./routes/public/SunburstChartPage";

const UnauthenticatedRouter = (): JSX.Element => {
  const routes: Record<string, JSX.Element> = {
    "*": <Page404 />,
    "/": <HomePage />,
    "/about": <AboutPage />,
    "/login": <SignInPage />,
    "/register": <SignUpPage />,
    "/forgot-password": <ForgotPasswordPage />,
    "/reset-password/:requestId": <ResetPasswordPage />,
    "/factors/:factorId": <FactorPage />,
    // "/factors-sunburst-chart": <SunburstChartPage />,
  };

  return (
    <Routes>
      {Object.keys(routes).map((path) => (
        <Route key={path} path={path} element={routes[path]} />
      ))}
    </Routes>
  );
};

export default UnauthenticatedRouter;
