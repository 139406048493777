import React from "react";

import { Divider, Drawer, Group, Stack } from "@mantine/core";
import { ReactComponent as AmolLogo } from "assets/logos/amol-logo.svg";
import CustomMediaQuery from "components/CustomMediaQuery";
import { useAuth } from "providers/AuthProvider";
import { Link } from "react-router-dom";

import AuthenticationButtons from "./AuthenticationButtons";
import UserProfile from "../components/UserProfileButton";
import classes from "./MobileMenu.module.scss";

const MobileMenu = ({
  opened,
  onClose,
}: {
  opened: boolean;
  onClose(): void;
}): JSX.Element => {
  const { user } = useAuth();

  return (
    <CustomMediaQuery largerThan="sm">
      <Drawer
        opened={opened}
        onClose={onClose}
        size="100%"
        padding="md"
        title={
          <Link to="/" onClick={onClose}>
            <AmolLogo height={40} width={40} />
          </Link>
        }
      >
        <Divider my="sm" mx="-md" color="gray.1" />
        <Stack gap="xs">
          <Link to="/" onClick={onClose} className={classes.link}>
            Home
          </Link>
          <Link to="/about" onClick={onClose} className={classes.link}>
            About
          </Link>
          {user && (
            <>
              <Link
                to="/my-growth-profile"
                onClick={onClose}
                className={classes.link}
              >
                My Growth Profile
              </Link>
            </>
          )}
        </Stack>
        <Divider my="sm" mx="-md" color="gray.1" />
        {user && (
          <Group className={classes.userProfile}>
            <UserProfile
              onClick={onClose}
              email={user.email}
              firstName={user.first_name}
              lastName={user.last_name}
            />
          </Group>
        )}
        {!user && <AuthenticationButtons />}
      </Drawer>
    </CustomMediaQuery>
  );
};

export default MobileMenu;
