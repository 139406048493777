import React from "react";

import { SimpleGrid, Stack } from "@mantine/core";
import { FactorGrade } from "modules/factors/utils/enums";
import { IGrowthProfileResponse } from "types/api/assessmentTypes";

import AssessmentResultCard from "../components/AssessmentResultCard";

const FactorAssessmentsResults = ({
  data,
}: {
  data: IGrowthProfileResponse;
}): JSX.Element => {
  return (
    <Stack my="lg">
      {data.strength.length > 0 && (
        <AssessmentResultCard
          grade={FactorGrade.STRENGTHS}
          data={data.strength}
          showSubtitle
        />
      )}
      <SimpleGrid mt="md" cols={{base: 2, md: 1}}>
        {data.opportunity.length > 0 && (
          <AssessmentResultCard
            grade={FactorGrade.CAPABILITIES}
            data={data.opportunity}
            showSubtitle
          />
        )}
        {data.growth.length > 0 && (
          <AssessmentResultCard
            grade={FactorGrade.GROWTH_ZONES}
            data={data.growth}
            showSubtitle
          />
        )}
      </SimpleGrid>
    </Stack>
  );
};

export default FactorAssessmentsResults;
