export enum InputType {
  // There are ids on input types in backend repo
  TEXT = 1,
  CHECKBOX,
  SELECT,
  MULTISELECT,
  CONDITIONAL_SELECT,
  SELECT_OR_INPUT,
  COUNTRY_SELECT,
  LADDER,
  RADIO,
  NUMBER,
  STATEMENT,
}

export enum OptionGroupName {
  YES_NO = "yes_no",
  LIMITING_NARRATIVES = "limiting_narratives",
  ZERO_TO_SIX_PLUS = "zero_to_six_plus",
  ONE_TO_SEVEN = "one_to_seven",
  ZERO_TO_THIRTY_PLUS = "zero_to_thirty_plus",
  // DEGREE_RADIO
  LINKERT = "linkert",
  LEVELS_OF_CONSIDERATION = "levels_of_consideration",
  INTERPERSONAL_ATTACHMENT = "interpersonal_attachment",
  PHYSICAL_HEALTH_SALT = "physical_health_salt",
  PHYSICAL_HEALTH_SUGAR = "physical_health_sugar",
  PHYSICAL_HEALTH_TARGET = "physical_health_target",
}

export type IOptionGroupTypes =
  | InputType.TEXT
  | InputType.CHECKBOX
  | InputType.SELECT
  | InputType.MULTISELECT
  | InputType.CONDITIONAL_SELECT
  | InputType.SELECT_OR_INPUT
  | InputType.COUNTRY_SELECT
  | InputType.LADDER
  | InputType.RADIO;
