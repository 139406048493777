import React from "react";

import {
  Paper,
  Title,
  Button,
  Container,
  Group,
  Anchor,
  Center,
  Box,
  PasswordInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconArrowLeft } from "@tabler/icons";
import { useParams } from "react-router-dom";
import useResetPassword from "hooks/auth/useResetPassword";
import {
  showErrorNotification,
  showSuccessNotification,
} from "utils/notifications";

import AuthLayout from "./components/AuthLayout";

interface FormValues {
  request_id: string;
  new_password: string;
  repeatPassword: string;
}

const ResetPasswordPage = (): JSX.Element => {
  const { requestId } = useParams<{ requestId: string }>();

  const resetPasswordMutation = useResetPassword()

  const form = useForm<FormValues>({
    initialValues: {
      request_id: requestId || "",
      new_password: "",
      repeatPassword: "",
    },

    validate: {
      new_password: (value) =>
        value.length <= 6
          ? "Password should include at least 6 characters"
          : null,
      repeatPassword: (value, values) =>
        value === values.new_password ? null : "Passwords don't match",
    },
  });

  const handleSubmit = (formValues: FormValues): void => {
    resetPasswordMutation.mutate(formValues, {
      onError: () => {
        showErrorNotification("Error", "Something went wrong!")
      },
      onSuccess: () => {
        showSuccessNotification(
          "Success",
          "Successfully changed your password!"
        )
      },
    })
  };

  return (
    <AuthLayout>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Container size={460} my={50}>
          <Paper shadow="xl" p={30} mt={30} radius="md">
            <Title fw={600} style={{ align: "center" }}>
              Enter your new password
            </Title>
            <PasswordInput
              label="Password"
              placeholder="Your password"
              required
              mt="md"
              {...form.getInputProps("new_password")}
            />
            <PasswordInput
              label="Repeat password"
              placeholder="Repeat your password"
              required
              mt="sm"
              {...form.getInputProps("repeatPassword")}
            />
            <Group justify="space-between" mt="lg">
              <Anchor href="/login" size="sm" fw={600}>
                <Center inline>
                  <IconArrowLeft size={12} stroke={1.5} />
                  <Box ml={5}>Back to login page</Box>
                </Center>
              </Anchor>
              <Button type="submit" loading={resetPasswordMutation.isPending}>
                Reset Password
              </Button>
            </Group>
          </Paper>
        </Container>
      </form>
    </AuthLayout>
  );
};

export default ResetPasswordPage;
