import React from "react";

import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons";

export const showSuccessNotification = (
  successTitle: string,
  successMessage?: string
): void => {
  notifications.show({
    title: successTitle,
    message: successMessage || "",
    color: "teal",
    autoClose: 5000,
    icon: <IconCheck size={16} />,
  });
};

export const showErrorNotification = (
  errorTitle: string,
  errorMessage?: string
): void => {
  notifications.show({
    title: errorTitle,
    message: errorMessage || "",
    color: "red",
    autoClose: 5000,
    icon: <IconX size={16} />,
  });
};
