import React from "react";

import { Button, Paper, ScrollArea, Table, Text, Title } from "@mantine/core";
import FactorGradeResult from "modules/factors/components/FactorGradeResult";
import { FactorGrade, FactorGradeTitle } from "modules/factors/utils/enums";
import { Link } from "react-router-dom";
import { IConnectedFactor } from "types/api/factorTypes";

const ConnectedFactors = ({
  data,
}: {
  data: IConnectedFactor[];
}): JSX.Element => {
  return (
    <Paper radius="md" withBorder p="lg">
      <Title order={2}>Connected Factors</Title>
      <Text mt="xs" color="dimmed">
        The below factors are highly interrelated with this factor. If you
        haven&apos;t already assessed them, it&apos;s recommended that you do so, and
        reflect on them in combination.
      </Text>
      <ScrollArea mt="xl">
        <Table fs="md" border={2} style={{ tableLayout: "fixed", minWidth: 700 }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map(({ name, grade, status, id }) => (
              <tr key={name}>
                <td>
                  <Text fw={500}>{name}</Text>
                </td>
                <td>
                  <FactorGradeResult
                    title={FactorGradeTitle[grade || FactorGrade.NOT_TAKEN]}
                    fullWidth={false}
                  />
                </td>
                <td>
                  <Button
                    component={Link}
                    to={`/factors/${id}/assessment/1`}
                    radius="xs"
                    variant="default"
                  >
                    {status === 2 ? "Take Assessment" : "Retake Assessment"}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ScrollArea>
    </Paper>
  );
};

export default ConnectedFactors;
