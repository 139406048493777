import React from "react";

import {
  Title,
  Text,
  Stack,
  useMantineTheme,
  Divider,
  Alert,
} from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons";
import ContentContainer from "components/ContentContainer";
import FactorsTable from "modules/factors/FactorsTable";

const IndividualAssessment = (): JSX.Element => {
  const theme = useMantineTheme();
  const icon = <IconInfoCircle />;
  return (
    <>
      <Divider />
      <ContentContainer py="xl">
        <Stack>
          <Title>
            Factors of Well-Being{" "}
            <Text
              span
              color={theme.colors.gray[6]}
              size="2xl"
              style={{
                fontFamily: "Alegreya, serif",
                fontWeight: 500,
                color: "var(--mantine-color-gray-6)",
              }}
            >
              Individual Assessments
            </Text>
          </Title>
          <Text size="lg" color={theme.colors.gray[6]} maw={900}>
            Every factor below is measured, at least slightly, by the full
            Growth Profile Assessment. However, most factors have their own
            individual assessments, too, which are more detailed. Once you&apos;ve
            taken the full GPA, visit a factor&apos;s page and take its individual
            assessment. It&apos;s recommended to start with your Growth Zones. This
            will give you deeper insights and a more detailed, accurate Growth
            Profile.
          </Text>
          <Alert variant="light" color="blue" icon={icon}>
            Results from the GPA will display (GPA). Once a factor&apos;s individual
            assessment is taken, you&apos;ll see the ‘(GPA)’ disappear.
          </Alert>
          <FactorsTable />
        </Stack>
      </ContentContainer>
    </>
  );
};

export default IndividualAssessment;
