import React from "react";

import { Avatar, Badge } from "@mantine/core";
import { IconQuestionCircle } from "@tabler/icons";

const QuestionsBadge = ({ label }: { label: string }): JSX.Element => {
  return (
    <Badge
      style={{ paddingLeft: 0 }}
      color="teal"
      variant="light"
      leftSection={
        <Avatar color="teal" size={22} mr={5}>
          <IconQuestionCircle />
        </Avatar>
      }
    >
      {label}
    </Badge>
  );
};

export default QuestionsBadge;
