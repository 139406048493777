import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { authorizedAxiosInstance } from "utils/axios-instance";

export default function useSaveGpaAnswers(): UseMutationResult<
  unknown,
  AxiosError,
  { [questionId: number]: number | number[] }
> {
  const queryClient = useQueryClient();

  const mutationFn = (payload: { [questionId: number]: number | number[] }) => {
    return authorizedAxiosInstance.post("/gpa", payload);
  };

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["gpaProgress"],
      });
    },
  });
}
