import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IGrowthProfileResponse } from "types/api/assessmentTypes";
import { authorizedAxiosInstance } from "utils/axios-instance";

export default function useGrowthProfileResults({
  enabled,
}: {
  enabled: boolean;
}): UseQueryResult<IGrowthProfileResponse, AxiosError> {
  const queryFn = (): Promise<IGrowthProfileResponse> => {
    return authorizedAxiosInstance.get("/factors/list");
  };

  return useQuery({
    queryKey: ["growthProfileResults", enabled],
    queryFn,
    enabled,
  });
}
