import React, { useEffect, useMemo, useState } from "react";

import {
  Group,
  Table,
  Text,
  MultiSelect,
  Tooltip,
  Select,
  useMantineTheme,
  SimpleGrid,
  Card,
  Stack,
  Box,
  keys,
  Loader,
  Center,
} from "@mantine/core";
import { IconSearch } from "@tabler/icons";
import MinutesBadge from "components/badges/MinutesBadge";
import CustomMediaQuery from "components/CustomMediaQuery";
import useFactors from "hooks/factors/useFactors";
import FactorGradeResult from "modules/factors/components/FactorGradeResult";
import {
  FactorCategoryTitle,
  FactorGrade,
  FactorGradeIsTaken,
  FactorGradeTitle,
} from "modules/factors/utils/enums";
import { useNavigate } from "react-router-dom";
import TextInput from "UI/TextInput";

import Th from "./components/Th";

interface RowData {
  factorId: string;
  label: string;
  category: string;
  taken: string;
  result: string;
  description: string;
  estimatedTime: string;
}

const filterData = (data: RowData[], search: string): RowData[] => {
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    keys(data[0]).some((key) => item[key].toLowerCase().includes(query))
  );
};
const sortData = (
  data: RowData[],
  payload: { sortBy: keyof RowData | null; reversed: boolean; search: string }
): RowData[] => {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return b[sortBy].localeCompare(a[sortBy]);
      }

      return a[sortBy].localeCompare(b[sortBy]);
    }),
    payload.search
  );
};

const FactorsTable = (): JSX.Element => {
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const { data: factorsData, isLoading: isFactorsLoading } = useFactors();

  const [search, setSearch] = useState("");
  const [data, setData] = useState<RowData[]>([]);
  const [sortedData, setSortedData] = useState<RowData[]>([]);
  const [sortBy, setSortBy] = useState<keyof RowData | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  useEffect(() => {
    if (factorsData) {
      const formattedData = factorsData.map(
        ({
          id,
          name,
          factor_category,
          grade,
          estimated_time,
          description,
        }) => ({
          factorId: String(id),
          label: name,
          category: FactorCategoryTitle[factor_category],
          taken: FactorGradeIsTaken[grade || FactorGrade.NOT_TAKEN],
          result: FactorGradeTitle[grade || FactorGrade.NOT_TAKEN],
          description,
          estimatedTime: String(estimated_time / 60),
        })
      );

      const sortedByLabel = formattedData.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      setData(sortedByLabel);
      setSortedData(sortedByLabel);
    }
  }, [factorsData]);

  const setSorting = (field: keyof RowData): void => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);

    if (field !== "label") {
      setSortedData(
        sortData(data, { sortBy: field, reversed, search }).sort((a, b) => {
          if (a[field] === b[field]) {
            return a.label.localeCompare(b.label); // Maintain alphabetical order within the group
          }
          return 0;
        })
      );
    } else {
      setSortedData(sortData(data, { sortBy: "label", reversed, search }));
    }
  };

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(data, {
        sortBy,
        reversed: reverseSortDirection,
        search: value,
      })
    );
  };

  const [categoriesFilter, setCategoriesFilter] = useState<
    RowData["category"][]
  >([]);
  const [statusFilter, setStatusFilter] = useState<RowData["taken"] | null>(
    null
  );
  const [resultsFilter, setResultsFilter] = useState<RowData["result"][]>([]);

  const filteredData = useMemo(
    () =>
      sortedData
        .filter(({ category }) =>
          categoriesFilter.length > 0
            ? categoriesFilter.includes(category)
            : category
        )
        .filter(({ taken }) =>
          statusFilter !== null ? taken === statusFilter : taken
        )
        .filter(({ result }) =>
          resultsFilter.length > 0 ? resultsFilter.includes(result) : result
        ),
    [categoriesFilter, resultsFilter, sortedData, statusFilter]
  );

  const ths: Array<{ id: keyof RowData; title: string }> = [
    { id: "label", title: "FACTOR" },
    { id: "category", title: "CATEGORY" },
    { id: "taken", title: "TAKEN" },
    { id: "result", title: "RESULT" },
  ];

  return (
    <Box mih={500}>
      <SimpleGrid
        mb="xl"
        cols={{ base: 1, sm: 4 }}
        spacing={{ base: "md", sm: "sm" }}
      >
        <TextInput
          name="any_field"
          placeholder="Search by any field"
          leftSection={<IconSearch size={14} stroke={1.5} />}
          value={search}
          onChange={handleSearchChange}
          radius="xs"
        />
        <MultiSelect
          placeholder="Categories"
          data={Object.values(FactorCategoryTitle)}
          onChange={setCategoriesFilter}
          radius="xs"
        />
        <Select
          placeholder="Status"
          clearable
          data={[
            { value: "Yes", label: "Taken" },
            { value: "No", label: "Not Taken" },
          ]}
          onChange={setStatusFilter}
          radius="xs"
        />
        <MultiSelect
          placeholder="Results"
          data={Object.values(FactorGradeTitle)}
          onChange={setResultsFilter}
          radius="xs"
        />
      </SimpleGrid>

      {isFactorsLoading && (
        <Center>
          <Loader />
        </Center>
      )}
      <CustomMediaQuery smallerThan="xs">
        <Table highlightOnHover verticalSpacing="md" fs="sm">
          <Table.Thead>
            <Table.Tr>
              {ths.map(({ id, title }) => (
                <Th
                  key={id}
                  sorted={sortBy === id}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting(id)}
                >
                  {title}
                </Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody style={{ border: `1px solid ${theme.colors.gray[2]}` }}>
            {filteredData &&
              filteredData.map(
                ({
                  factorId,
                  label,
                  category,
                  taken,
                  result,
                  description,
                  estimatedTime,
                }) => (
                  <Table.Tr
                    key={factorId}
                    onClick={() => navigate(`/factors/${factorId}`)}
                    style={{ cursor: "pointer" }}
                  >
                    <Table.Td>
                      <Group wrap="nowrap">
                        <Tooltip
                          label={description}
                          position="top-start"
                          transitionProps={{ duration: 200 }}
                          maw={500}
                          multiline
                          withArrow
                        >
                          <Text>{label}</Text>
                        </Tooltip>
                        <MinutesBadge
                          value={estimatedTime}
                          size="sm"
                          iconProps={{ size: "xs", mr: -1 }}
                        />
                      </Group>
                    </Table.Td>
                    <Table.Td>{category}</Table.Td>
                    <Table.Td>{taken}</Table.Td>
                    <Table.Td>
                      <FactorGradeResult title={result} />
                    </Table.Td>
                  </Table.Tr>
                )
              )}
          </Table.Tbody>
        </Table>
      </CustomMediaQuery>
      <CustomMediaQuery largerThan="xs">
        <Stack>
          {filteredData &&
            filteredData.map(
              ({
                factorId,
                label,
                category,
                taken,
                result,
                description,
                estimatedTime,
              }) => (
                <Card key={factorId} withBorder>
                  <Group wrap="nowrap" justify="space-between" align="start">
                    <Text size="lg" fw={500}>
                      {label}
                    </Text>
                    <MinutesBadge
                      value={estimatedTime}
                      size="sm"
                      iconProps={{ size: "xs", mr: -1 }}
                    />
                  </Group>

                  <Text size="sm" color="dimmed" mt="md">
                    {description}
                  </Text>
                  <Text color="dimmed" size="sm" mt="md">
                    Category:{" "}
                    <Text span color="gray.7" fw={500}>
                      {category}
                    </Text>
                  </Text>
                  <Text color="dimmed" size="sm" mt="xs" mb="lg">
                    Is taken:{" "}
                    <Text span color="gray.7" fw={500}>
                      {taken}
                    </Text>
                  </Text>
                  <FactorGradeResult
                    size="lg"
                    title={result}
                    onClick={() => navigate(`/factors/${factorId}`)}
                  />
                </Card>
              )
            )}
        </Stack>
      </CustomMediaQuery>
    </Box>
  );
};

export default FactorsTable;
