export enum FactorCategory {
  ENABLERS = 1,
  PURPOSE,
  LOVE,
  EXPRESSION_AND_SERVICE,
  HAPPINESS,
  DISCOVERY,
  HINDRANCES,
  CONSIDERING,
}

export const FactorCategoryTitle: { [key: number]: string } = {
  [FactorCategory.ENABLERS]: "Enablers",
  [FactorCategory.PURPOSE]: "Purpose",
  [FactorCategory.LOVE]: "Love",
  [FactorCategory.EXPRESSION_AND_SERVICE]: "Expression & Service",
  [FactorCategory.HAPPINESS]: "Happiness",
  [FactorCategory.DISCOVERY]: "Discovery",
  [FactorCategory.HINDRANCES]: "Hindrances",
  [FactorCategory.CONSIDERING]: "Considering",
};

export enum FactorGrade {
  NOT_TAKEN = 1,
  GROWTH_ZONES = 2,
  CAPABILITIES = 3,
  STRENGTHS = 4,
  GPA_GROWTH_ZONES = 5,
  GPA_CAPABILITIES = 6,
  GPA_STRENGTHS = 7,
  TAKEN = 8,
}

export const FactorGradeIsTaken: { [key: number]: string } = {
  [FactorGrade.NOT_TAKEN]: "No",
  [FactorGrade.GROWTH_ZONES]: "Yes",
  [FactorGrade.CAPABILITIES]: "Yes",
  [FactorGrade.STRENGTHS]: "Yes",
  [FactorGrade.GPA_GROWTH_ZONES]: "Yes",
  [FactorGrade.GPA_CAPABILITIES]: "Yes",
  [FactorGrade.GPA_STRENGTHS]: "Yes",
  [FactorGrade.TAKEN]: "Yes",
};

export const FactorGradeTitle: { [key: number]: string } = {
  [FactorGrade.NOT_TAKEN]: "Not Taken",
  [FactorGrade.GROWTH_ZONES]: "Growth Zones",
  [FactorGrade.CAPABILITIES]: "Capabilities",
  [FactorGrade.STRENGTHS]: "Strengths",
  [FactorGrade.GPA_GROWTH_ZONES]: "Growth Zones (GPA)",
  [FactorGrade.GPA_CAPABILITIES]: "Capabilities (GPA)",
  [FactorGrade.GPA_STRENGTHS]: "Strengths (GPA)",
  [FactorGrade.TAKEN]: "Taken",
};

export const FactorGradeSubtitle: { [key: number]: string } = {
  [FactorGrade.GROWTH_ZONES]:
    "These factors may be holding you back from greater well-being. Embrace challenge! Work on these aspects of life and you will see meaningful results.",
  [FactorGrade.CAPABILITIES]:
    "With these factors, you’re moving in a good direction, and, they could benefit from some practice, too.",
  [FactorGrade.STRENGTHS]:
    "Your results show proficiency in these well-being factors. Consider ‘leaning in’ to these skills to improve your relationships and practice living well.",
};

export const FactorGradeColor: { [key: number]: string } = {
  [FactorGrade.NOT_TAKEN]: "gray",
  [FactorGrade.GROWTH_ZONES]: "red",
  [FactorGrade.CAPABILITIES]: "orange",
  [FactorGrade.STRENGTHS]: "green",
  [FactorGrade.GPA_GROWTH_ZONES]: "red",
  [FactorGrade.GPA_CAPABILITIES]: "orange",
  [FactorGrade.GPA_STRENGTHS]: "green",
  [FactorGrade.TAKEN]: "green",
};

export const FactorBadgeColor: { [key: number]: string } = {
  [FactorGrade.NOT_TAKEN]: "gray",
  [FactorGrade.GROWTH_ZONES]: "red",
  [FactorGrade.CAPABILITIES]: "orange",
  [FactorGrade.STRENGTHS]: "green",
  [FactorGrade.GPA_GROWTH_ZONES]: "red",
  [FactorGrade.GPA_CAPABILITIES]: "orange",
  [FactorGrade.GPA_STRENGTHS]: "green",
  [FactorGrade.TAKEN]: "green",
};
