import {useMutation, UseMutationResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { authorizedAxiosInstance } from "utils/axios-instance";
import {MembershipType} from "types/api/paymentTypes";

interface Payload {
  plan: MembershipType;
  redirectUrl?: string
}

export default function useGetSubscriptionPaymentUrl():
  UseMutationResult<
    string,
    AxiosError,
    Payload
  > {
  const mutationFn = (payload: Payload): Promise<string> => {
    return authorizedAxiosInstance.post("/stripe/create-checkout-session", {
      ...payload,
      plan: payload.plan.toLowerCase()
    })

  };

  return useMutation({
    mutationFn,
  });
}
