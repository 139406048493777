import React from "react";

import { Box, BoxProps } from "@mantine/core";
import useBem from "hooks/useBem";

import "./ContentContainer.scss";

interface IContentContainerProps extends BoxProps {
  className?: string;
  children?: React.ReactNode;
}

const ContentContainer = ({
  className,
  children,
  ...props
}: IContentContainerProps): JSX.Element => {
  const { bem, bemBlock } = useBem("ContentContainer");
  return (
    <Box {...props} className={bem(bemBlock(), className)} px="md">
      {children}
    </Box>
  );
};

export default ContentContainer;
