import React from "react";

import { Stack, Title } from "@mantine/core";
import FactorsTable from "modules/factors/FactorsTable";

const AllFactors = (): JSX.Element => {
  return (
    <Stack gap="sm" my="xl">
      <Title>All Factors</Title>
      <FactorsTable />
    </Stack>
  );
};

export default AllFactors;
