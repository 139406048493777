import React from "react";

import AssessmentNavigationPanel from "components/assessment/AssessmentNavigationPanel";
import { IAssessmentProgress } from "types/api/question";

const GpaAssessmentNavigationPanel = ({
  progressData,
}: {
  progressData: IAssessmentProgress;
}): JSX.Element => {
  return (
    <AssessmentNavigationPanel
      title="Gpa Assessment"
      goBackRoute="/"
      questionsCountData={{
        answered: progressData.answered_count,
        total: progressData.questions_count,
      }}
    />
  );
};

export default GpaAssessmentNavigationPanel;
