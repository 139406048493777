import React from "react";

import { Accordion, Box, ThemeIcon, Title } from "@mantine/core";
import { IconPlus } from "@tabler/icons";
import ContentContainer from "components/ContentContainer";

import classes from "./AboutPage.module.scss";
import AboutAssessmentCenter from "./modules/AboutAssessmentCenter";
import AssessmentMeasuring from "./modules/AsessmentMeasuring";
import DataProcessing from "./modules/DataProcessing";
import NextSteps from "./modules/NextSteps";

const HomePage = (): JSX.Element => {
  return (
    <ContentContainer mih="100vh">
      <Box maw={680} mx="auto">
        <Title style={{ align: "center" }} my="xl">
          Frequently Asked Questions
        </Title>

        <Accordion
          chevronPosition="right"
          defaultValue="about"
          chevronSize={50}
          variant="separated"
          disableChevronRotation
          chevron={
            <ThemeIcon radius="xl" size={32}>
              <IconPlus size={18} stroke={1.5} />
            </ThemeIcon>
          }
        >
          <Accordion.Item className={classes.item} value="about">
            <Accordion.Control>
              What is the Assessment Center all about?
            </Accordion.Control>
            <Accordion.Panel>
              <AboutAssessmentCenter />
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item className={classes.item} value="next-steps">
            <Accordion.Control>What Am I Measuring?</Accordion.Control>
            <Accordion.Panel>
              <AssessmentMeasuring />
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item className={classes.item} value="data-processing">
            <Accordion.Control>What Happens to the Data?</Accordion.Control>
            <Accordion.Panel>
              <DataProcessing />
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item className={classes.item} value="measuring">
            <Accordion.Control>
              What&apos;s next for the Assessment Center?
            </Accordion.Control>
            <Accordion.Panel>
              <NextSteps />
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Box>
    </ContentContainer>
  );
};

export default HomePage;
