import React from "react";

import { Paper, Table, Text, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import TokenHelper from "utils/tokenHelper";

const HealthFactorResultBreakdown = (): JSX.Element => {
  const getHealthFactorResultOverview = async () => {
    const { accessToken, csrfToken } = TokenHelper.get();
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/factors/5/result-overview`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch grow cycles");
    }

    return response.json();
  };

  const { data } = useQuery({
    queryKey: ["health-factor-breakdown"],
    queryFn: getHealthFactorResultOverview,
  });

  const rows = data
    ? Object.entries(data).map(([key, value]) => (
        <tr key={key}>
          <td>
            <Text fw={700}>{key}</Text>
          </td>
          <td>{String(value)}</td>
        </tr>
      ))
    : null;

  return (
    <Paper radius="md" withBorder p="lg">
      <Title order={2}>Health Factor Explanation</Title>
      <Text mt="xs" color="dimmed">
        The below is a breakdown of your Health Factor result. You can use this
        to understand how your score was calculated and what it means.
      </Text>
      <Table>
        <thead>
          <tr>
            <th>Health Factor</th>
            <th>Answer Overview</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </Paper>
  );
};

export default HealthFactorResultBreakdown;
