import React from "react";

import { MultiSelect, Select, TextInput } from "@mantine/core";
import countries from "i18n-iso-countries";
import { IAssessmentQuestion } from "types/api/assessmentTypes";
import { IDemographicProfileAnswers } from "types/api/demographicProfileTypes";
import { InputType, OptionGroupName } from "types/inputTypes";
import LinkerSelect from "UI/LinkerSelect";
import RadioSelect from "UI/RadioSelect";

import LadderInput from "./LadderInput";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const countriesObj = countries.getNames("en", { select: "official" });
const countriesArray: string[] = Object.keys(countriesObj).map(
  (key) => countriesObj[key]
);

const QuestionInputSwitch = ({
  question,
  answers,
  setAnswers,
}: {
  question: IAssessmentQuestion;
  answers: IDemographicProfileAnswers;
  setAnswers: React.Dispatch<React.SetStateAction<IDemographicProfileAnswers>>;
}): JSX.Element => {
  const handleChange = (value: IDemographicProfileAnswers[0]): void => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [question.id]: value,
    }));
  };

  const inputValue: IDemographicProfileAnswers[0] | undefined =
    answers[question.id];

  const formattedSelectOptionChoices = question.option_group.option_choices.map(
    ({ id, name }) => ({
      label: name,
      value: id.toString(),
    })
  );

  if (question.input_type === InputType.TEXT) {
    if (!inputValue && inputValue !== "") {
      handleChange("");
    }
    return (
      <TextInput
        label={question.text}
        placeholder="Type your answer"
        value={inputValue as string}
        onChange={(event) => handleChange(event.currentTarget.value)}
      />
    );
  }

  switch (question.input_type) {
    case InputType.CHECKBOX || InputType.MULTISELECT: {
      const noneOptionChoices = formattedSelectOptionChoices.filter(
        ({ label }) => label === "None"
      );
      const answersArray = inputValue as number[];
      const isSelectedNone: boolean =
        noneOptionChoices[0]?.value && answersArray
          ? answersArray.includes(+noneOptionChoices[0].value)
          : false;

      return (
        <MultiSelect
          label={question.text}
          value={answersArray?.map((value: number) => value.toString())}
          placeholder="Choose few"
          onChange={(values) => handleChange(values.map(Number))}
          data={
            isSelectedNone ? noneOptionChoices : formattedSelectOptionChoices
          }
        />
      );
    }

    case InputType.COUNTRY_SELECT: {
      return (
        <Select
          searchable
          label={question.text}
          value={inputValue as string}
          placeholder="Choose one"
          onChange={(value) => handleChange(value as string)}
          data={countriesArray}
        />
      );
    }

    case InputType.LADDER: {
      return (
        <LadderInput
          label={question.text}
          value={inputValue as string}
          onChange={(value) => handleChange(value as string)}
          data={formattedSelectOptionChoices}
        />
      );
    }

    case InputType.RADIO: {
      if (question.option_group.name === OptionGroupName.LINKERT) {
        return (
          <LinkerSelect
            label={question.text}
            value={inputValue as string}
            onChange={(value) => handleChange(value as string)}
            options={formattedSelectOptionChoices}
            styles={{
              root: {
                ".mantine-11ycyx1": {
                  justifyContent: "center",
                },
              },
            }}
          />
        );
      }
      return (
        <RadioSelect
          label={question.text}
          value={inputValue as string}
          onChange={(value) => handleChange(value as string)}
          options={formattedSelectOptionChoices}
        />
      );
    }

    case InputType.NUMBER: {
      return (
        <TextInput
          label={question.text}
          placeholder="Type your answer"
          value={inputValue as string}
          onChange={(event) => handleChange(event.currentTarget.value)}
          type="number"
        />
      );
    }

    // input_type equals InputTypeIdEnum.SELECT_OR_INPUT or other
    default:
      return (
        <Select
          label={question.text}
          value={inputValue?.toString()}
          placeholder="Choose one"
          onChange={(value) => handleChange(Number(value))}
          data={formattedSelectOptionChoices}
        />
      );
  }
};

export default QuestionInputSwitch;
