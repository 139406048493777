import React, { useState } from "react";

import {
  Button,
  Checkbox,
  Modal,
  ModalProps,
  Stack,
  Title,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import useAcceptGpaInstructions from "hooks/gpa/useAcceptGpaInstructions";
import { useAuth } from "providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import {
  showErrorNotification,
  showSuccessNotification,
} from "utils/notifications";

const instructions: string[] = [
  "Garbage In, Garbage Out:  with full attention, your results won't be garbage.",
  "Answer how you are now, not how you want to be in the future.",
  "Do NOT answer what you think you should do, or wish you would do, or things you no longer do.",
  "There are no right or wrong responses, so for your own sake, answer honestly.",
  "Although some questions are similar to one another, they are distinct/different; try not to let your responses to one question influence your response to other questions.",
  "Question wording: some of the language/phrasing may be off for you, and please go with what you think the intent of the question is, rather than the exact letter of it in those cases.",
  "Please respond quickly and try not to take too long deliberating upon any one question.",
  "Use the full range of the scale: if 7 feels right, mark a 7, if 1 seems like a fit, go ahead and mark 1.",
];

const GpaInstructionModal = ({ opened, onClose }: ModalProps): JSX.Element => {
  const navigate = useNavigate();

  const { user } = useAuth();

  const isInstructionAccepted = user?.gpa_instruction;

  const acceptGpaInstructionMutation = useAcceptGpaInstructions();

  const [value, setValue] = useState<string[]>([]);

  const handleAccept = (): void => {
    acceptGpaInstructionMutation.mutate(undefined, {
      onError: () => {
        showErrorNotification("Submit is failed");
      },
      onSuccess: () => {
        showSuccessNotification(
          "Successfully accepted",
          "Instruction is submitted successfully"
        );
      },
    });
  };

  const isAcceptDisabled = value.length !== instructions.length;

  const handleClose = (): void => {
    if (!isInstructionAccepted) {
      showNotification({
        title: "Redirected to home page",
        message:
          "You must accept the instruction before proceeding with the assessment",
        autoClose: 5000,
      });
      navigate("/");
    }
    onClose();
  };

  return (
    <Modal
      size="lg"
      opened={opened}
      onClose={handleClose}
      closeOnClickOutside
      withCloseButton
      title={
        <Title order={2} style={{ align: "center" }}>
          Instruction
        </Title>
      }
    >
      <Stack gap="xl">
        <Checkbox.Group
          label="Before you begin, please read these instructions and indicate that you
        understand how to best take the assessment."
          value={value}
          onChange={setValue}
        >
          <Stack gap="lg" mt="sm">
            {instructions.map((instruction) => (
              <Checkbox
                key={instruction}
                label={instruction}
                value={instruction}
                styles={{
                  input: {
                    cursor: "pointer",
                  },
                  label: {
                    cursor: "pointer",
                  },
                }}
              />
            ))}
          </Stack>
        </Checkbox.Group>
        <Button
          disabled={isAcceptDisabled}
          onClick={handleAccept}
          loading={acceptGpaInstructionMutation.isPending}
        >
          Accept
        </Button>
      </Stack>
    </Modal>
  );
};

export default GpaInstructionModal;
