import React from "react";

import CustomMediaQuery from "components/CustomMediaQuery";
import useBem from "hooks/useBem";

import "./AuthLayout.scss";

const AuthLayout = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { bemBlock, bemElement } = useBem("AuthLayout");
  return (
    <div className={bemBlock()}>
      <CustomMediaQuery smallerThan="md">
        <div className={bemElement("creative-container")} />
      </CustomMediaQuery>
      <div className={bemElement("form-container")}>{children}</div>
    </div>
  );
};

export default AuthLayout;
