import React from "react";

import { useMediaQuery } from "@mantine/hooks";

interface Breakpoints {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
}

const CustomMediaQuery: React.FC<{
  smallerThan?: keyof Breakpoints;
  largerThan?: keyof Breakpoints;
  children: React.ReactNode;
}> = ({ smallerThan, largerThan, children }) => {
  const breakpoints: Breakpoints = {
    xs: "36rem",
    sm: "48rem",
    md: "62rem",
    lg: "75rem",
    xl: "88rem",
  };

  let query = "";

  if (smallerThan && breakpoints[smallerThan]) {
    query = `(min-width: ${breakpoints[smallerThan]})`;
  } else if (largerThan && breakpoints[largerThan]) {
    query = `(max-width: ${breakpoints[largerThan]})`;
  }

  // Using the useMediaQuery hook with our query
  const matches = useMediaQuery(query);

  // Conditionally rendering children based on the media query match
  return matches ? <>{children}</> : null;
};

export default CustomMediaQuery;
