import React from "react";

import { SimpleGrid, Stack, Title, useMantineTheme } from "@mantine/core";
import ContentContainer from "components/ContentContainer";
import useGrowthProfileResults from "hooks/gpa/useGrowthProfileResults";
import { useAuth } from "providers/AuthProvider";

import AssessmentResultCard from "../components/AssessmentResultCard";

const GrowthProfileAssessment = (): JSX.Element => {
  const theme = useMantineTheme();

  const { user } = useAuth();
  const isGpaPassed = user?.gpa_test;

  const { data } = useGrowthProfileResults({
    enabled: isGpaPassed,
  });

  const isDataHasNeededFields =
    !!data &&
    !!data?.strength &&
    !!data?.strength_gpa &&
    !!data?.opportunity &&
    !!data?.opportunity_gpa &&
    !!data?.growth &&
    !!data?.growth_gpa;

  return (
    <Stack bg={theme.colors.gray[0]} py={`calc(${theme.spacing.lg} * 3)`}>
      <ContentContainer>
        <Title>Understand Your Results</Title>
        <Title style={{ color: "var(--mantine-color-gray-6)" }}>
          Growth Profile Assessment™
        </Title>
        {isDataHasNeededFields && (
          <SimpleGrid mt="xl" cols={{ base: 3, xl: 3, lg: 3, md: 2, xs: 1 }}>
            <AssessmentResultCard
              title="Strengths"
              description="Your results show proficiency in these well-being factors. Consider ‘leaning in’ to these skills to improve your relationships and practice living well."
              factors={(data?.strength?.length
                ? data?.strength
                : data?.strength_gpa
              ).map(({ id, name }) => ({ id, name }))}
            />
            <AssessmentResultCard
              title="Capabilities"
              description="With these factors, you’re moving in a good direction, and, they could benefit from some practice, too."
              factors={(data?.opportunity?.length
                ? data?.opportunity
                : data?.opportunity_gpa
              ).map(({ id, name }) => ({ id, name }))}
            />
            <AssessmentResultCard
              title="Growth Zones"
              description="These factors may be holding you back from greater well-being. Embrace challenge! Work on these aspects of life and you will see meaningful results."
              factors={(data?.growth?.length
                ? data?.growth
                : data?.growth_gpa
              ).map(({ id, name }) => ({ id, name }))}
            />
          </SimpleGrid>
        )}
      </ContentContainer>
    </Stack>
  );
};

export default GrowthProfileAssessment;
