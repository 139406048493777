import React from "react";

import { Group, Stack, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconFlask } from "@tabler/icons";
import PrimaryButton from "UI/PrimaryButton";
import DemographicProfileDetailsModal from "layout/modules/Header/modules/DemographicProfileDetailsModal";

const ContributeToScience = (): JSX.Element => {
  const [isModalOpen, { open: openModal, close: closeModal }] =
    useDisclosure(false);

  return (
    <>
      <Stack gap="md" my="lg" bg="cyan.0" px="md" py="xl">
        <Group justify="center" align="center" mt="xl" wrap="nowrap" gap="xs">
          <IconFlask size="2rem" />
          <Title order={2} style={{ align: "center" }}>
            Contribute to Science!
          </Title>
          <IconFlask size="2rem" />
        </Group>

        <Text maw={380} mx="auto" size="sm" mb="md" fw="bold" style={{ align: "center" }}>
          By answering a handful of demographic questions, you can help
          researchers learn more about a life well-lived. Your data is
          anonymized and never sold for a profit. It only takes a minute!
        </Text>
        <PrimaryButton
          radius="xs"
          size="sm"
          mb="xl"
          mx="auto"
          onClick={openModal}
        >
          Tell Us About Yourself
        </PrimaryButton>
      </Stack>
      <DemographicProfileDetailsModal opened={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default ContributeToScience;
