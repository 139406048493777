import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { IAssessmentProgress } from "types/api/question";
import { authorizedAxiosInstance } from "utils/axios-instance";

export default function useDemographicProfileProgress({
  enabled = true,
}: {
  enabled?: boolean;
}): UseQueryResult<IAssessmentProgress> {
  const queryFn = (): Promise<IAssessmentProgress> => {
    return authorizedAxiosInstance.get("/dp/progress");
  };

  return useQuery({
    queryFn,
    queryKey: ["demographicProfileProgress", enabled],
    enabled,
  });
}
