import { useQuery, UseQueryResult } from "@tanstack/react-query";
import {AxiosError} from "axios";
import { IConnectedFactor } from "types/api/factorTypes";
import { authorizedAxiosInstance } from "utils/axios-instance";

export default function useConnectedFactors(
  factorId: string
): UseQueryResult<IConnectedFactor, AxiosError> {
  const queryFn = (): Promise<IConnectedFactor> => {
    return authorizedAxiosInstance.get(
      `/factors/${factorId}/connected-factors`
    );
  };

  return useQuery({
    queryFn,
    queryKey: ["factorsConnected", factorId],
  });
}
