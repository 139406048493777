import React from "react";

import { Group } from "@mantine/core";
import { AssessmentCarouselValue } from "components/assessment/AssessmentCarousel";
import ContentContainer from "components/ContentContainer";
import { IEmblaCarouselProps } from "components/EmblaCarousel";
import useSaveGpaAnswers from "hooks/gpa/useSaveGpaAnswers";
import { useNavigate, useParams } from "react-router-dom";
import { IPaginatedAssessmentQuestions } from "types/api/assessmentTypes";
import Pagination from "UI/Pagination";
import { showErrorNotification } from "utils/notifications";

const GpaAssessmentPagination = ({
  value,
  emblaApi,
  totalPage,
  questionsData,
}: {
  value: AssessmentCarouselValue;
  emblaApi: IEmblaCarouselProps["emblaApi"];
  totalPage: number;
  questionsData: IPaginatedAssessmentQuestions;
}): JSX.Element => {
  const { page } = useParams();
  const navigate = useNavigate();

  const saveGpaAnswersMutation = useSaveGpaAnswers();

  const isAllQuestionsAnswered =
    Object.keys(value).length >= questionsData.results.length;

  const handleSaveAnswers = (): void => {
    saveGpaAnswersMutation.mutate(value, {
      onError: () => {
        showErrorNotification("Answers save is failed");
      },
    });
  };

  const handleChangePage = (pageNumber: number): void => {
    const valueCount = Object.values(value).length;
    if (valueCount > 0) {
      handleSaveAnswers();
    }

    if (emblaApi) {
      emblaApi.scrollTo(0);
    }
    navigate(`/gpa-assessment/${pageNumber}`);
  };

  return (
    <ContentContainer>
      <Group justify="center">
        <Pagination
          page={Number(page)}
          total={totalPage}
          onChange={handleChangePage}
          isNextDisabled={!isAllQuestionsAnswered}
        />
      </Group>
    </ContentContainer>
  );
};

export default GpaAssessmentPagination;
