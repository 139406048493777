import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IFactor } from "types/api/factorTypes";
import { authorizedAxiosInstance } from "utils/axios-instance";

export default function useFactor(
  factorId: string
): UseQueryResult<IFactor, AxiosError> {
  const queryFn = (): Promise<IFactor> => {
    return authorizedAxiosInstance.get(`/factors/${factorId}`);
  };

  return useQuery({
    queryFn,
    queryKey: ["factors", factorId],
    enabled: !!factorId,
  });
}
