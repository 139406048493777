import React from "react";

import {Burger, Button, Group} from "@mantine/core";
import { ReactComponent as AmolLogo } from "assets/logos/amol-logo.svg";
import ContentContainer from "components/ContentContainer";
import { useAuth } from "providers/AuthProvider";
import { Link } from "react-router-dom";

import AuthenticationButtons from "./AuthenticationButtons";
import classes from "./DesktopHeader.module.scss";
import UserProfile from "../components/UserProfileButton";
import {IconExternalLink} from "@tabler/icons";

const DesktopHeader = ({
  mobileMenuOpened,
  toggleMobileMenu,
}: {
  mobileMenuOpened: boolean;
  toggleMobileMenu(): void;
}): JSX.Element => {
  const { user } = useAuth();

  return (
    <div style={{ height: "60px", position: "relative" }}>
      <ContentContainer>
        <Group justify="space-between" style={{ height: "100%" }}>
          <Group justify="center" align="center">
            <Link to="/">
              <AmolLogo height={40} width={40} />
            </Link>
            <Button
              component={Link}
              to="http://ameaningoflife.org/"
              variant="subtle"
              rightSection={<IconExternalLink size={14} />}
            >
              Go To AMoL
            </Button>
          </Group>

          <Group className={classes.hiddenMobile} style={{ height: "100%" }}>
            <Link to="/" className={classes.link}>
              Home
            </Link>
            {user && (
              <Link to="/my-growth-profile" className={classes.link}>
                My Growth Profile
              </Link>
            )}
            <Link to="/about" className={classes.link}>
              About
            </Link>
            {/*<Link to="/factors-sunburst-chart" className={classes.link}>*/}
            {/*  Factors Sunburst*/}
            {/*</Link>*/}
          </Group>

          {user && (
            <UserProfile
              className={`${classes.link} ${classes.hiddenMobile}`}
              email={user.email}
              firstName={user.first_name}
              lastName={user.last_name}
            />
          )}

          {!user && <AuthenticationButtons className={classes.hiddenMobile} />}
          <Burger
            className={classes.hiddenDesktop}
            opened={mobileMenuOpened}
            onClick={toggleMobileMenu}
          />
        </Group>
      </ContentContainer>
    </div>
  );
};

export default DesktopHeader;
