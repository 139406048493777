import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IAssessmentValue } from "types/api/assessmentTypes";
import { authorizedAxiosInstance } from "utils/axios-instance";

function useSaveFactorAnswers(
  factorId: string
): UseMutationResult<unknown, AxiosError, IAssessmentValue> {
  const queryClient = useQueryClient();

  const mutationFn = (payload: IAssessmentValue) => {
    return authorizedAxiosInstance.post(
      `/factors/${factorId}/questions/`,
      payload
    );
  };

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["factorProgress", factorId] });
    },
  });
}

export default useSaveFactorAnswers;
