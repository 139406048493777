import React from "react";

import {
  Card,
  Divider,
  Group,
  List,
  SimpleGrid,
  Stack,
  Text,
  ThemeIcon,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons";
import ContentContainer from "components/ContentContainer";
import { useAuth } from "providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "UI/PrimaryButton";

const cards = [
  {
    title: "Full Access to the Assessment Center",
    description:
      "The most comprehensive, integrated, scientific assessment of well-being and flourishing on the web",
    points: [
      "Get an overview of your Growth Profile, measuring 50 well-being factors in one assessment.",
      "Detailed, individual assessments on each factor, measuring over 225 subfactors.",
      "Find your Strengths, Capabilities, and Growth Zones.",
    ],
  },
  {
    title: "Full Access to AMeaningOfLife.org member content",
    points: [
      "Hundreds of pages covering a score of pages for members-only",
      "Hundreds of downloadable Practice and Exercise printouts",
      "Members-only videos",
      "Resource pages for all subjects",
    ],
  },
  {
    title: "... and more",
    description:
      "NOTE that AMeaningOfLife.org is in development, and numerous sections (especially Enablers) have not been created yet, or are in the process of being created.  If there is a specific, perhaps singular, topic you’re wanting, check to ensure it exists before becoming a member. If it isn’t, (A) know that there are plenty of pages that are (e.g., Purpose, Dating, Friendship, Gratitude,Hope, Money, more!, and (B) consider donating (non-profit) to help this project along.  :-)",
    points: [
      "Members-only newsletters with priceless tools from around the web",
      "First access to new content",
      "Cancel whenever you want",
      "Support a non-profit (NGO); we are a 501(c)(3)",
    ],
  },
];

const BecomeMemberAssessment = (): JSX.Element => {
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const { user } = useAuth();

  const handleBecomeMember = (): void => {
    if (user) {
      navigate("/select-subscription", { replace: true });
    } else {
      navigate("/register", { replace: true });
    }
  };

  return (
    <Stack bg={theme.colors.gray[0]}>
      <Divider />
      <ContentContainer
        pt={`calc(${theme.spacing.xl} * 2)`}
        pb={`calc(${theme.spacing.xl} * 4)`}
      >
        <Title>Become a member of the Assessment Center</Title>
        {cards.map(({ title, description, points }) => (
          <Card key={title} withBorder radius="sm" mt="xl" p="xl">
            <SimpleGrid
              cols={{ base: 2, sm: 1 }}
              spacing={{ base: "xl", sm: "sm" }}
            >
              <Stack gap="xs">
                <Title order={3}>{title}</Title>
                {description && <Text color="dimmed">{description}</Text>}
              </Stack>
              <List
                spacing="sm"
                size="sm"
                icon={
                  <ThemeIcon color="yellow" size={20} radius="xl">
                    <IconCheck size={12} stroke={1.5} />
                  </ThemeIcon>
                }
              >
                {points.map((text) => (
                  <List.Item key={text}>{text}</List.Item>
                ))}
              </List>
            </SimpleGrid>
          </Card>
        ))}

        <Group mt="xl" justify="center">
          <PrimaryButton size="md" onClick={handleBecomeMember}>
            Become a member for $10
          </PrimaryButton>
        </Group>
      </ContentContainer>
    </Stack>
  );
};

export default BecomeMemberAssessment;
