import React, { useState } from "react";

import {
  Button,
  Stack,
  Group,
  Loader,
  Modal,
  ModalProps,
  Title,
  Text,
  List,
} from "@mantine/core";
import useDemographicProfileQuestions from "hooks/demographic-profile/useDemographicProfileQuestions";
import useSaveDemographicProfileAnswers from "hooks/demographic-profile/useSaveDemographicProfileAnswers";
import { IAssessmentQuestion } from "types/api/assessmentTypes";
import { IDemographicProfileAnswers } from "types/api/demographicProfileTypes";
import {
  showErrorNotification,
  showSuccessNotification,
} from "utils/notifications";

import QuestionInputSwitch from "../components/QuestionInputSwitch";

const Greeting = (): JSX.Element => {
  return (
    <>
      <Text>Tell us about you (anonymously), for science!</Text>
      <Text>
        By taking a few minutes for these questions, you will help researchers
        discover unanswered mysteries about human well-being. And, guess what:
      </Text>
      <List>
        <List.Item>
          The data is anonymized and not identifiable with you as an individual.
        </List.Item>
        <List.Item>
          The data is never sold for monetary gain nor marketing purposes.{" "}
          <br /> We are a non-profit and support open science!
        </List.Item>
      </List>
    </>
  );
};

const DemographicProfileIntroModal = ({
  opened,
  onClose,
}: ModalProps): JSX.Element => {
  const [answers, setAnswers] = useState<IDemographicProfileAnswers>({});

  // Only fetch the first page of questions
  const { data: questionsData, isLoading: isQuestionsLoading } =
    useDemographicProfileQuestions({ page: 1 });

  const saveAnswersMutation = useSaveDemographicProfileAnswers();

  const handleSubmit = () => {
    saveAnswersMutation.mutate(answers, {
      onError: () => {
        showErrorNotification("Submit is failed")
      },
      onSuccess: () => {
        showSuccessNotification(
          "Submit is successful",
          "Demographic questions are submitted successfully"
        );
        onClose();
      }
    })
  };

  return (
    <Modal
      size="lg"
      opened={opened}
      onClose={onClose}
      closeOnClickOutside={false}
      closeOnEscape={false}
      withCloseButton={false}
      title={
        <Title order={2} style={{ align: "center" }}>
          Demographic Profiling Questions
        </Title>
      }
    >
      <Stack>
        <Greeting />
        {isQuestionsLoading && (
          <Group justify="center">
            <Loader />
          </Group>
        )}
        {questionsData && (
          <>
            <Stack>
              {questionsData?.results.map(
                (question: IAssessmentQuestion) => (
                  <QuestionInputSwitch
                    key={question.id}
                    question={question}
                    answers={answers}
                    setAnswers={setAnswers}
                  />
                )
              )}
            </Stack>
            <Button
              mt="lg"
              onClick={handleSubmit}
              fullWidth
              disabled={
                Object.keys(answers).length !==
                questionsData?.results.length
              }
            >
              Submit
            </Button>
          </>
        )}
      </Stack>
    </Modal>
  );
};

export default DemographicProfileIntroModal;
