import React, { useEffect } from "react";

import { Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import useDpIntroAnswered from "hooks/useDpIntroAnswered";
import { useAuth } from "providers/AuthProvider";

import DemographicProfileDetailsModal from "./Header/modules/DemographicProfileDetailsModal";
import { getViewedDp, updateViewedDp } from "../../utils/functions";

const DemographicForm = (): JSX.Element => {
  const { user } = useAuth();
  const isPaid = user?.is_paid;
  const isDpCompleted = user?.dp_test;

  const { isDemoAnswered: isIntroAnswered } = useDpIntroAnswered(user);

  const [
    isDemographicModalOpen,
    { open: openDemographicModal, close: closeDemographicModal },
  ] = useDisclosure(false);

  const openModal = () =>
    openConfirmModal({
      title: "Contribute to Science!",
      children: (
        <Text size="sm">
          By answering a few demographics questions, you can help researchers
          study the mysteries of human well-being. Your data is kept anonymous,
          and never sold for profit.
        </Text>
      ),
      labels: { confirm: "Sure!", cancel: "Maybe Later" },
      onCancel: () => {
        updateViewedDp(true);
      },
      onConfirm: () => {
        updateViewedDp(true);
        openDemographicModal();
      },
      zIndex: 10000,
    });

  useEffect(() => {
    const isViewed = getViewedDp();

    if (!isViewed && isPaid && !isDpCompleted && isIntroAnswered) {
      openModal();
    }
  }, [isPaid, isDpCompleted]);

  return (
    <>
      <DemographicProfileDetailsModal
        opened={isDemographicModalOpen}
        onClose={closeDemographicModal}
      />
    </>
  );
};

export default DemographicForm;
