import React, { useMemo } from "react";

import { Group, Progress, Stack, Title } from "@mantine/core";
import MinutesBadge from "components/badges/MinutesBadge";
import ContentContainer from "components/ContentContainer";
import CustomMediaQuery from "components/CustomMediaQuery";

import BackButton from "../BackButton";
import QuestionsBadge from "../badges/QuestionsBadge";

interface IAssessmentNavigationPanelsProps {
  questionsCountData: {
    answered: number;
    total: number;
  };
  title: string;
  goBackRoute: string;
}

const AssessmentNavigationPanel = ({
  questionsCountData,
  title,
  goBackRoute,
}: IAssessmentNavigationPanelsProps): JSX.Element => {
  const { answered, total } = questionsCountData;

  const progressPercentage = useMemo(
    () => (answered * 100) / total,
    [answered, total]
  );

  const questionsLeft = useMemo(() => total - answered, [answered, total]);

  const remainingQuestionMinutes = useMemo(() => {
    return questionsLeft / 5;
  }, [questionsLeft]);

  const BadgeCounters = (
    <>
      <QuestionsBadge label={`${questionsLeft} questions left`} />
      <MinutesBadge value={remainingQuestionMinutes} />
    </>
  );

  return (
    <>
      <Progress value={progressPercentage} radius={0} color="green" w="100%" />
      <ContentContainer>
        <Group justify="space-between">
          <BackButton to={goBackRoute} replace />
          <CustomMediaQuery smallerThan="xs">
            <Stack align="flex-end" gap="xs">
              <Title order={2}>{title}</Title>
              <Group wrap="nowrap">{BadgeCounters}</Group>
            </Stack>
          </CustomMediaQuery>
          <CustomMediaQuery largerThan="xs">
            <div>
              <Title order={2}>{title}</Title>
              <Group>{BadgeCounters}</Group>
            </div>
          </CustomMediaQuery>
        </Group>
      </ContentContainer>
    </>
  );
};

export default AssessmentNavigationPanel;
