import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { authorizedAxiosInstance } from "utils/axios-instance";

export default function useSubmitDemographicProfile(): UseMutationResult<
  unknown,
  AxiosError,
  void
> {
  const queryClient = useQueryClient()

  const mutationFn = (): Promise<unknown> => {
    return authorizedAxiosInstance.post("/dp/submit");
  };

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["me"]
      })
    }
  });
}
