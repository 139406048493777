import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";
import Page404 from "routes/error/Page404";
import FactorAssessmentPage from "routes/private/FactorAssessmentPage";
import GpaAssessmentPage from "routes/private/GpaAssessmentPage";
import MyGrowthProfilePage from "routes/private/MyGrowthProfilePage";
import UserProfilePage from "routes/private/UserProfilePage";
import AboutPage from "routes/public/AboutPage/AboutPage";
import SelectSubscriptionPage from "routes/public/auth/SelectSubscriptionPage";
import SignInPage from "routes/public/auth/SignInPage";
import SubscriptionPaymentSuccessPage from "routes/public/auth/SubscriptionPaymentSuccessPage";
import FactorPage from "routes/public/FactorPage";
import HomePage from "routes/public/HomePage";

import { useAuth } from "./providers/AuthProvider";

const ProtectedRouter = ({
  showConditions,
  children,
}: {
  children: JSX.Element;
  showConditions: boolean[];
}): JSX.Element => {
  return showConditions.includes(false) ? (
    <Navigate to="/" replace />
  ) : (
    children
  );
};

const AuthenticatedRouter = (): JSX.Element => {
  const { user } = useAuth();
  const isGpaPassed = user?.gpa_test;
  const isUserPaid = user?.is_paid;

  const routes: Record<string, JSX.Element> = {
    "*": <Page404 />,
    "/": <HomePage />,
    "/about": <AboutPage />,
    "/auth/payment/success": (
      <ProtectedRouter showConditions={[!isUserPaid]}>
        <SubscriptionPaymentSuccessPage />
      </ProtectedRouter>
    ),
    "/select-subscription": (
      <ProtectedRouter showConditions={[!isUserPaid]}>
        <SelectSubscriptionPage />
      </ProtectedRouter>
    ),
    "/profile": <UserProfilePage />,
    "/gpa-assessment/:page": (
      <ProtectedRouter showConditions={[isUserPaid, !isGpaPassed]}>
        <GpaAssessmentPage />
      </ProtectedRouter>
    ),
    "/factors/:factorId": <FactorPage />,
    "/factors/:factorId/assessment/:page": (
      <ProtectedRouter showConditions={[isUserPaid]}>
        <FactorAssessmentPage />
      </ProtectedRouter>
    ),
    "/my-growth-profile": <MyGrowthProfilePage />,
    "/login": <SignInPage />,
    // "/factors-sunburst-chart": <SunburstChartPage />,
  };

  return (
    <Routes>
      {Object.keys(routes).map((path) => (
        <Route key={path} path={path} element={routes[path]} />
      ))}
    </Routes>
  );
};

export default AuthenticatedRouter;
