import React from "react";

import { List, Stack, Text, ThemeIcon } from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons";

const NextSteps = (): JSX.Element => (
  <Stack>
    <Text>
      We are a 501c3 non-profit (NGO) organization: a very small group of individuals
      who are passionate about bringing more joy into the world.
    </Text>
    <Text>We have many hopeful plans for the future. To name just a few:</Text>
    <List
      spacing="xs"
      size="md"
      center
      icon={
        <ThemeIcon color="teal" size={16} radius="xl">
          <IconCircleCheck size={16} />
        </ThemeIcon>
      }
    >
      <List.Item>
        Publishing findings after significant data is gathered and analyzed.
      </List.Item>
      <List.Item>
        Improving the web app and website, offering more & better guides to
        living well.
      </List.Item>
      <List.Item>
        Enabling friends and colleagues to give each other factor feedback
        through the Assessment Center.
      </List.Item>
      <List.Item>So much more…</List.Item>
    </List>
    <Text span>
      By joining us, you are helping that vision live and thrive.
    </Text>
  </Stack>
);

export default NextSteps;
