import React, { useMemo } from "react";

import {
  Card,
  Title,
  Text,
  Group,
  SimpleGrid,
  Stack,
  useMantineTheme,
  ThemeIcon,
} from "@mantine/core";
import { IconCircleHalf2, IconStairs, IconTriangle } from "@tabler/icons";
import CustomMediaQuery from "components/CustomMediaQuery";
import useGpaPausedPage from "hooks/gpa/useGpaPausedPage";
import { useAuth } from "providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "UI/PrimaryButton";

const FullGrowthProfileAssessment = (): JSX.Element => {
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const { user } = useAuth();
  const isPaid = user?.is_paid;

  const { data: pausedGpaPageData } = useGpaPausedPage({
    enabled: !!user,
  });

  const resumedPageNumber = pausedGpaPageData?.resumed_page;

  const handleTakeAssessment = (): void => {
    let route = "/login";
    if (user) {
      if (isPaid) route = `/gpa-assessment/${resumedPageNumber || 1}`;
      else route = "/select-subscription";
    }

    navigate(route);
  };

  const assessmentButtonLabel = useMemo((): string => {
    if (resumedPageNumber) return "Continue";
    if (isPaid) return "Take Assessment";

    return "Subscribe and Take Assessment";
  }, [resumedPageNumber, isPaid]);

  return (
    <Card
      radius="xs"
      shadow="xs"
      p={0}
      style={{ zIndex: 99, overflow: "hidden" }}
    >
      <SimpleGrid cols={{ base: 1, sm: 2 }}>
        <Stack align="flex-start" p="xl">
          <CustomMediaQuery smallerThan="xs">
            <Title order={2}>Full Growth Profile Assessment</Title>
          </CustomMediaQuery>
          <CustomMediaQuery largerThan="xs">
            <Title order={3}>Full Growth Profile Assessment</Title>
          </CustomMediaQuery>

          <Text size="md">
            <Text fw="bold" style={{ display: "inline-block" }}>
              Start here.
            </Text>{" "}
            This overview assessment takes a broad measure of all factors,
            giving you a glimpse of all your strengths, capabilities, and growth
            zones.
          </Text>
          <Text color="dimmed" size="sm">
            ~ 55 minutes · 50 factors · 250 questions
          </Text>
          <CustomMediaQuery largerThan="xs">
            <PrimaryButton size="sm" fullWidth onClick={handleTakeAssessment}>
              {assessmentButtonLabel}
            </PrimaryButton>
          </CustomMediaQuery>
          <CustomMediaQuery smallerThan="xs">
            <PrimaryButton size="md" onClick={handleTakeAssessment}>
              {assessmentButtonLabel}
            </PrimaryButton>
          </CustomMediaQuery>
        </Stack>
        <CustomMediaQuery smallerThan="sm">
          <Group justify="center" bg={theme.colors.gray[7]} p="xl" gap="xl">
            <Stack align="center">
              <ThemeIcon size={100} radius={100} color="lime">
                <IconTriangle size={75} color="green" />
              </ThemeIcon>
              <Text color="white" fw={500}>
                Strengths
              </Text>
            </Stack>
            <Stack align="center">
              <ThemeIcon size={100} radius={100} color="orange" p={0}>
                <IconCircleHalf2 size={75} color={theme.colors.orange[9]} />
              </ThemeIcon>
              <Text color="white" fw={500}>
                Capabilities
              </Text>
            </Stack>
            <Stack align="center">
              <ThemeIcon size={100} radius={100} color="red" p={0}>
                <IconStairs size={75} color={theme.colors.red[9]} />
              </ThemeIcon>
              <Text color="white" fw={500}>
                Growth Zones
              </Text>
            </Stack>
          </Group>
        </CustomMediaQuery>
      </SimpleGrid>
    </Card>
  );
};

export default FullGrowthProfileAssessment;
