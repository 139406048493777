import React, { useEffect } from "react";

import {
  Anchor,
  Button,
  Paper,
  PasswordInput,
  TextInput,
  Title,
  Text,
  Group,
  Container,
  Divider,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useAuth } from "providers/AuthProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ISessionAuthTokens } from "types/api/authTypes";
import tokenHelper from "utils/tokenHelper";

import AuthLayout from "./components/AuthLayout";
import SocialButtons from "./components/SocialButtons";

type FormData = {
  email: string;
  password: string;
};

const SignInPage = (): JSX.Element => {
  const { user, login } = useAuth();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect_uri");
  const navigate = useNavigate();


  useEffect(() => {
    if (user && redirectUrl) {
      const { accessToken, csrfToken } = tokenHelper.get();

      window.location.replace(
        `${redirectUrl}?access=${accessToken}?csrf=${csrfToken}`
      );
    }
    if (user && redirectUrl === null) {
      navigate('/')
    }
  }, [redirectUrl, user]);

  const form = useForm<FormData>({
    initialValues: {
      email: "",
      password: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: (value) =>
        value.length <= 6
          ? "Password should include at least 6 characters"
          : null,
    },
  });

  const handleSubmit = (formData: FormData): void => {
    login(formData);
  };

  return (
    <AuthLayout>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Container size={420} my={40}>
          <Paper shadow="xl" p={30} mt={30} radius="md">
            <Title fw={600} style={{ align: "center" }}>
              Sign In
            </Title>
            <Text color="dimmed" size="sm" mt={5} mb={26} style={{ align: "center" }}>
              Do not have an account yet?{" "}
              <Anchor<"a"> href="/register" size="sm">
                Create account
              </Anchor>
            </Text>
            <SocialButtons />
            <Divider
              label="Or continue with email"
              labelPosition="center"
              my="lg"
            />
            <TextInput
              label="Email"
              placeholder="your@mail.com"
              required
              {...form.getInputProps("email")}
            />
            <PasswordInput
              label="Password"
              placeholder="Your password"
              required
              mt="md"
              {...form.getInputProps("password")}
            />
            <Group justify="space-between" mt="lg">
              <Anchor<"a"> href="/forgot-password" size="sm" fw={600}>
                Forgot password?
              </Anchor>
            </Group>
            <Button type="submit" mt="xl" fullWidth>
              Sign in
            </Button>
            <Text mt="lg" color="dimmed" size="sm" mx="auto" style={{ align: "center" }}>
              Click &quot;Sign In&quot; to agree to Assessment Center&apos;s
              <br />
              <Anchor
                href="https://ameaningoflife.org/terms-of-service/"
                target="_blank"
              >
                Terms of Service
              </Anchor>{" "}
              and acknowledge that Assessment Center&apos;s{" "}
              <Anchor
                href="https://ameaningoflife.org/privacy-policy/"
                target="_blank"
              >
                Privacy Policy
              </Anchor>{" "}
              applies to you.
            </Text>
          </Paper>
        </Container>
      </form>
    </AuthLayout>
  );
};

export default SignInPage;
