import React, { useEffect, useState } from "react";

import { Stack } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { AssessmentCarouselValue } from "components/assessment/AssessmentCarousel";
import LoadingOverlay from "components/LoadingOverlay";
import useEmblaCarousel from "embla-carousel-react";
import useGpaProgress from "hooks/gpa/useGpaProgress";
import useGpaQuestions from "hooks/gpa/useGpaQuestions";
import useDpIntroAnswered from "hooks/useDpIntroAnswered";
import DemographicProfileIntroModal from "layout/modules/Header/modules/DemographicProfileIntroModal";
import { useAuth } from "providers/AuthProvider";
import { Navigate, useParams } from "react-router-dom";
import {
  getActualAssessmentProgressData,
  getAssessmentsValueFromData,
} from "utils/assessmentHelper";

import GpaAssessmentCarousel from "./modules/GpaAssessmentCarousel";
import GpaAssessmentNavigationPanel from "./modules/GpaAssessmentNavigationPanel";
import GpaAssessmentPagination from "./modules/GpaAssessmentPagination";
import GpaInstructionModal from "./modules/GpaInstructionModal";

const GpaAssessmentPage = (): JSX.Element => {
  const { page } = useParams();
  const { user } = useAuth();

  const isInstructionVisible = user?.gpa_instruction;
  const isGpaPassed = user?.gpa_test;

  const {
    data: progressData,
    isLoading: isProgressLoading,
    isFetching: isProgressFetching,
  } = useGpaProgress();
  const {
    data: questionsData,
    isLoading: isGpaQuestionsLoading,
    isFetching: isGpaQuestionsFetching,
  } = useGpaQuestions({
    page,
    page_size: 10,
  });

  const { isDemoAnswered, refetch } = useDpIntroAnswered();
  const [opened, setOpened] = useState<boolean>(isInstructionVisible);
  const [isDemoOpened, handlers] = useDisclosure(false);
  const [value, setValue] = useState<AssessmentCarouselValue>({});
  const [initialValueCount, setInitialValueCount] = useState(0);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    axis: "y",
    loop: false,
    draggable: false,
  });

  const newValueCount = Object.keys(value).length - initialValueCount;

  const handleCloseModal = (): void => {
    setOpened(false);
  };

  const handleDemoClose = (): void => {
    refetch();
    handlers.close();
  };

  useEffect(() => {
    if (questionsData) {
      const initialValue = getAssessmentsValueFromData(questionsData);
      setInitialValueCount(Object.keys(initialValue).length);
      setValue(initialValue);
    }
  }, [questionsData]);

  useEffect(() => {
    if (Number(page) >= 5 && !isDemoAnswered) {
      handlers.open();
    } else {
      handlers.close();
    }
  }, [page, isDemoAnswered]);

  if (isGpaPassed) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <Stack pos="relative" mih="100%" mb="lg">
        <LoadingOverlay
          visible={
            isGpaQuestionsLoading ||
            isGpaQuestionsFetching ||
            isProgressLoading ||
            isProgressFetching
          }
        />
        {questionsData && progressData && (
          <>
            <GpaAssessmentNavigationPanel
              progressData={getActualAssessmentProgressData(
                progressData,
                newValueCount
              )}
            />
            <GpaAssessmentCarousel
              emblaApi={emblaApi}
              emblaRef={emblaRef}
              value={value}
              setValue={setValue}
              progressData={getActualAssessmentProgressData(
                progressData,
                newValueCount
              )}
              questionsData={questionsData}
              isModalOpen={opened || isDemoOpened}
            />
            <GpaAssessmentPagination
              emblaApi={emblaApi}
              value={value}
              totalPage={Math.ceil(questionsData?.count / 10)}
              questionsData={questionsData}
            />
          </>
        )}
      </Stack>
      <GpaInstructionModal opened={opened} onClose={handleCloseModal} />
      <DemographicProfileIntroModal
        opened={isDemoOpened}
        onClose={handleDemoClose}
      />
    </>
  );
};

export default GpaAssessmentPage;
