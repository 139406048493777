import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import {authorizedAxiosInstance} from "utils/axios-instance";
import {IRefreshTokenResponse, IUpdateCurrentUserProfileRequest} from "types/api/authTypes";

export default function useUpdateProfile(): UseMutationResult<
  IRefreshTokenResponse,
  AxiosError<any>,
  IUpdateCurrentUserProfileRequest
> {
  const queryClient = useQueryClient();

  const mutationFn = (
    payload: IUpdateCurrentUserProfileRequest
  ): Promise<IRefreshTokenResponse> => {
    return authorizedAxiosInstance.post("/users/current/edit", payload);
  };

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
  });
}
