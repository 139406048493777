import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IAssessmentProgress } from "types/api/question";
import { authorizedAxiosInstance } from "utils/axios-instance";

export default function useGpaProgress(): UseQueryResult<
  IAssessmentProgress,
  AxiosError
> {
  const queryFn = (): Promise<IAssessmentProgress> => {
    return authorizedAxiosInstance.get("/gpa/progress");
  };

  return useQuery({
    queryFn,
    queryKey: ["gpaProgress"],
  });
}
