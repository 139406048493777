import React from "react";

import {
  Button,
  Group,
  Paper,
  SimpleGrid,
  Text,
} from "@mantine/core";
import {
  IconCoin,
  IconCreditCard,
  IconReceipt2,
  IconUserPlus,
  TablerIcon,
} from "@tabler/icons";
import dayjs from "dayjs/esm";
import { useAuth } from "providers/AuthProvider";
import { Membership } from "types/api/paymentTypes";
import { showErrorNotification } from "utils/notifications";
import useGetManageSubscriptionUrl from "hooks/payment/useGetManageSubscriptionUrl";

import classes from "./SubscriptionInfoCards.module.scss";

type IconType = "user" | "discount" | "receipt" | "coin";

const icons: Record<IconType, TablerIcon> = {
  user: IconUserPlus,
  discount: IconCreditCard,
  receipt: IconReceipt2,
  coin: IconCoin,
};

const SubscriptionInfoCards = (): JSX.Element => {
  const { user } = useAuth();

  const getManageSubscriptionUrlMutation = useGetManageSubscriptionUrl()

  const handleManageSubscription = (): void => {
    getManageSubscriptionUrlMutation.mutate(undefined, {
      onError: () => {
        showErrorNotification("Editing subscription payment failed")
      },
      onSuccess: (subscriptionUrl) => {
        window.location.replace(subscriptionUrl)
      }
    })
  };

  const data: Array<{
    title: string;
    icon: IconType;
    value: string;
    description: string | React.ReactNode;
  }> = [
    {
      title: "Last payment",
      icon: "receipt",
      value: `$${user?.user_profile.payment_amount || "-"}`,
      description: user?.user_profile.last_payment_date
        ? dayjs(user?.user_profile.last_payment_date).format("D MMMM YYYY")
        : "-",
    },
    {
      title: "Next payment",
      icon: "coin",
      value: `$${user?.user_profile.payment_amount || "-"}`,
      description: user?.user_profile.last_payment_date
        ? dayjs(user?.user_profile.next_payment_date).format("D MMMM YYYY")
        : "-",
    },
    {
      title: "Payment method: Stripe",
      icon: "discount",
      value: "****",
      description: (
        <Button
          size="xs"
          ml="auto"
          onClick={handleManageSubscription}
          loading={getManageSubscriptionUrlMutation.isPending}
          disabled={user?.user_profile.membership_type === Membership.UNPAID}
        >
          Manage my subscription
        </Button>
      ),
    },
  ];

  return (
    <Paper radius="md" bg="gray.0" withBorder p="lg" h="100%" w="100%">
      <SimpleGrid cols={{ base: 3, md: 2, xs: 1 }}>
        {data.map((stat) => {
          const Icon = icons[stat.icon];

          return (
            <Paper withBorder p="md" radius="md" key={stat.title}>
              <Group justify="space-between">
                <Text size="xs" color="dimmed">
                  {stat.title}
                </Text>
                <Icon className={classes.icon} size={22} stroke={1.5} />
              </Group>

              <Group align="flex-end" gap="xs" mt={25}>
                <Text className={classes.value}>{stat.value}</Text>
                {typeof stat.description === "string" ? (
                  <Text
                    mb={2}
                    size="sm"
                    style={{ color: "gray" }}
                    w={500}
                    className={classes.description}
                  >
                    {stat.description}
                  </Text>
                ) : (
                  stat.description
                )}
              </Group>
            </Paper>
          );
        })}
      </SimpleGrid>
    </Paper>
  );
};

export default SubscriptionInfoCards;
