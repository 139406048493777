import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IFactor } from "types/api/factorTypes";
import { authorizedAxiosInstance } from "utils/axios-instance";

export default function useFactors(): UseQueryResult<IFactor[], AxiosError> {
  const queryFn = (): Promise<IFactor[]> => {
    return authorizedAxiosInstance.get("/factors");
  };

  return useQuery({
    queryFn,
    queryKey: ["factors"],
  });
}
