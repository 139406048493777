import { useQuery, UseQueryResult } from "@tanstack/react-query";
import {AxiosError, AxiosResponse} from "axios";
import { IPaginatedDemographicQuestions } from "types/api/demographicProfileTypes";
import { IPaginatedRequest } from "types/api/pagination";
import { authorizedAxiosInstance } from "utils/axios-instance";

export default function useDemographicProfileQuestions(
  params: IPaginatedRequest & { enabled?: boolean }
): UseQueryResult<IPaginatedDemographicQuestions, AxiosError> {
  const queryFn = (): Promise<
    AxiosResponse<IPaginatedDemographicQuestions>
  > => {
    return authorizedAxiosInstance.get("/dp/questions", { params });
  };

  return useQuery({
    queryFn,
    queryKey: ["demographicProfileQuestions", params],
    enabled: params.enabled,
  });
}
