import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IDemographicResultTypes } from "types/api/demographicProfileTypes";
import { IPaginatedRequest } from "types/api/pagination";
import { authorizedAxiosInstance } from "utils/axios-instance";

export default function useDemographicProfileResults(
  params: IPaginatedRequest & { enabled?: boolean }
): UseQueryResult<IDemographicResultTypes[], AxiosError> {
  const queryFn = (): Promise<IDemographicResultTypes[]> => {
    return authorizedAxiosInstance.get("/dp/results", { params });
  };

  return useQuery({
    queryFn,
    queryKey: ["demographicProfileResults", params],
    enabled: params.enabled,
  });
}
