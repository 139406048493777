import {useMutation, UseMutationResult } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { authorizedAxiosInstance } from "utils/axios-instance";

export default function useGetManageSubscriptionUrl(): UseMutationResult<string, AxiosError, void> {
  const mutationFn = (): Promise<string> => {
    return authorizedAxiosInstance.get("/stripe/customer-portal")

  };

  return useMutation({
    mutationFn,
  });
}
