import React, { useState } from "react";

import {
  Avatar,
  Button,
  Group,
  Paper,
  Stack,
  Text,
  Modal,
  Title,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import ContentContainer from "components/ContentContainer";
import DemographicProfileDetailsModal from "layout/modules/Header/modules/DemographicProfileDetailsModal";
import { useAuth } from "providers/AuthProvider";
import { IUpdateCurrentUserProfileRequest } from "types/api/authTypes";
import useUpdateProfile from "hooks/auth/useUpdateProfile";
import {showErrorNotification, showSuccessNotification} from "utils/notifications";

import MembershipBadge from "./components/MembershipBadge";
import SubscriptionInfoCards from "./components/SubscriptionInfoCards";

const UserProfilePage = (): JSX.Element => {
  const { user } = useAuth();

  const [openedEditProfile, setOpenedEditProfile] = useState(false);
  const [isModalOpen, { open: openModal, close: closeModal }] =
    useDisclosure(false);

  const { logout } = useAuth();

  const form = useForm<IUpdateCurrentUserProfileRequest>({
    initialValues: {
      email: user?.email || "",
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const updateProfileMutation = useUpdateProfile()

  const handleSubmit = (formValues: IUpdateCurrentUserProfileRequest): void => {
    updateProfileMutation.mutate(formValues, {
      onError: (e) => {
        showErrorNotification(
          "Update is failed",
          e.response?.data.error || "Profile update is failed"
        )
      },
      onSuccess: () => {
        showSuccessNotification("Profile updated successfully")
        setOpenedEditProfile(false);
      },
    })
  };

  return (
    <>
      <ContentContainer py="md">
        {user && (
          <Stack>
            <Group align="start">
              <Paper
                radius="md"
                withBorder
                p="lg"
                maw={320}
                style={{
                  "@media (max-width: 755px)": {
                    margin: "0 auto",
                    maxWidth: "unset",
                    width: "100%",
                  },
                }}
              >
                <Avatar size={120} radius={120} mx="auto">
                  {user?.first_name[0] + user?.last_name[0]}
                </Avatar>
                <Text size="lg" fw={500} mt="md" style={{ align: "center" }}>
                  {`${user?.first_name} ${user?.last_name}`}
                </Text>
                <Text color="dimmed" size="sm" style={{ align: "center" }}>
                  {user?.email} •{" "}
                  <MembershipBadge type={user?.user_profile.membership_type} />
                </Text>

                <Group mt="md" gap="xs">
                  <Button
                    variant="light"
                    color="red"
                    fullWidth
                    onClick={() => logout()}
                  >
                    Log out
                  </Button>
                  <Button
                    variant="default"
                    fullWidth
                    onClick={() => setOpenedEditProfile(true)}
                  >
                    Edit Profile
                  </Button>
                </Group>
              </Paper>
              <Paper
                radius="md"
                withBorder
                p="lg"
                px="xl"
                maw={320}
                style={{
                  "@media (max-width: 755px)": {
                    margin: "0 auto",
                    maxWidth: "unset",
                    width: "100%",
                  },
                }}
              >
                <Text
                  mb="sm"
                  color="dimmed"
                  size="sm"
                  style={{ align: "center" }}
                >
                  Contribute to Science! By answering a few demographics
                  questions, you can help researchers study the mysteries of
                  human well-being. Your data is kept anonymous, and never sold
                  for profit.
                </Text>
                <Button fullWidth onClick={openModal}>
                  Update Demographics
                </Button>
              </Paper>
            </Group>

            <SubscriptionInfoCards />
            {/* {user?.dp_test && <DemographicResultCards />} No longer displayed */}
          </Stack>
        )}
      </ContentContainer>
      <Modal
        title={<Title order={2}>Edit Profile</Title>}
        zIndex={9999}
        opened={openedEditProfile}
        onClose={() => setOpenedEditProfile(false)}
        overlayProps={{ blur: 3 }}
      >
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            label="Name"
            placeholder={user?.first_name}
            required
            {...form.getInputProps("first_name")}
          />
          <TextInput
            label="Surname"
            placeholder={user?.last_name}
            required
            mt="sm"
            {...form.getInputProps("last_name")}
          />
          <TextInput
            label="Email"
            placeholder={user?.email}
            required
            mt="sm"
            {...form.getInputProps("email")}
          />
          <Group mt="md" justify="right">
            <Button
              variant="subtle"
              onClick={() => setOpenedEditProfile(false)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              loading={updateProfileMutation.isPending}
            >
              Update Profile
            </Button>
          </Group>
        </form>
      </Modal>
      <DemographicProfileDetailsModal
        opened={isModalOpen}
        onClose={closeModal}
      />
    </>
  );
};

export default UserProfilePage;
