import React from "react";

import { Stack } from "@mantine/core";
import { useAuth } from "providers/AuthProvider";

import BecomeMemberAssessment from "./modules/BecomeMemberAssessment";
import FullGrowthProfileCard from "./modules/FullGrowthProfileCard";
import GrowthProfileAssessmentResults from "./modules/GrowthProfileAssessment";
import IndividualAssessment from "./modules/IndividualAssessment";
import WelcomeBlock from "./modules/WelcomeBlock";

const HomePage = (): JSX.Element => {
  const { user } = useAuth();
  const isPaid = user?.is_paid;
  const isGpaTestPassed = user?.gpa_test;

  return (
    <Stack gap={0}>
      {!isGpaTestPassed && <WelcomeBlock />}
      {!isGpaTestPassed && <FullGrowthProfileCard />}
      {isGpaTestPassed && <GrowthProfileAssessmentResults />}
      {!isPaid && <BecomeMemberAssessment />}
      <IndividualAssessment />
    </Stack>
  );
};

export default HomePage;
