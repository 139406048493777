import React from "react";

import { Button, ButtonProps, Flex, Group, Text } from "@mantine/core";

const FivePointSelect = ({
  options,
  onChange,
  value,
}: Omit<ButtonProps, "children"> & {
  options: { label: string; value: string }[];
  onChange: (id: string) => void;
  value?: string;
}): JSX.Element => {
  return (
    <Flex direction="column">
      <Group justify="center" gap="xs">
        {options.map((option) => (
          <Button
            size="md"
            key={option.value}
            value={option.value}
            variant={value === option.value ? "filled" : "outline"}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </Button>
        ))}
      </Group>
      <Group justify="space-between">
        <Text color="red">Strongly disagree</Text>
        <Text color="green">Strongly agree</Text>
      </Group>
    </Flex>
  );
};

export default FivePointSelect;
