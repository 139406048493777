import React from "react";

import {
  Flex,
  Text,
  Radio,
  RadioGroupProps,
  RadioProps,
  Group,
  MantineSize,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

const LinkerSelect = ({
  options,
  radioProps,
  ...radioGroupProps
}: Omit<RadioGroupProps, "children"> & {
  options: { label: string; value: string }[];
  radioProps?: RadioProps;
}): JSX.Element => {
  const theme = useMantineTheme();
  const mobileScreen = useMediaQuery("(max-width: 500px)");

  const colors: Record<number, string> = {
    0: "red",
    1: "red",
    2: "red",
    3: "gray",
    4: "green",
    5: "green",
    6: "green",
  };

  const borderColors: Record<number, string> = {
    0: theme.colors.red[6],
    1: theme.colors.red[6],
    2: theme.colors.red[6],
    3: "gray",
    4: theme.colors.green[6],
    5: theme.colors.green[6],
    6: theme.colors.green[6],
  };

  const sizes: Record<number, MantineSize> = {
    0: "xl",
    1: "lg",
    2: "md",
    3: "md",
    4: "md",
    5: "lg",
    6: "xl",
  };

  return (
    <Flex direction="column">
      <Radio.Group {...radioGroupProps}>
        <Group gap={mobileScreen ? "xs" : "md"} justify="center">
          {options.map(({ value }, index) => {
            return (
              <Radio
                {...radioProps}
                color={colors[index]}
                size={sizes[index]}
                key={value}
                value={value}
                style={{
                  radio: {
                    cursor: "pointer",
                    border: `2px solid ${borderColors[index]}`,
                  },
                  label: { cursor: "pointer" },
                }}
              />
            );
          })}
        </Group>
      </Radio.Group>
      <Group justify="space-between">
        <Text color="red">Strongly disagree</Text>
        <Text color="green">Strongly agree</Text>
      </Group>
    </Flex>
  );
};

export default LinkerSelect;
