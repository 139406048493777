import React, { useMemo } from "react";

import {
  Card,
  Group,
  SimpleGrid,
  Stack,
  Text,
  Title,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { IconCircleHalf2, IconStairs, IconTriangle } from "@tabler/icons";
import {
  FactorGrade,
  FactorGradeColor,
  FactorGradeSubtitle,
  FactorGradeTitle,
} from "modules/factors/utils/enums";
import { useNavigate } from "react-router-dom";
import { IGrowthProfileGrade } from "types/api/assessmentTypes";

const AssessmentResultCard = ({
  grade,
  data,
  showSubtitle = false,
}: {
  grade: FactorGrade;
  data: IGrowthProfileGrade[];
  showSubtitle?: boolean;
}): JSX.Element => {
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const cardBackgroundColor =
    grade === FactorGrade.STRENGTHS || grade === FactorGrade.GPA_STRENGTHS
      ? theme.colors.lime[5]
      : FactorGradeColor[grade];

  const Icon = useMemo((): JSX.Element => {
    if (
      grade === FactorGrade.STRENGTHS ||
      grade === FactorGrade.GPA_STRENGTHS
    ) {
      return <IconTriangle size={75} color="green" />;
    }
    if (
      grade === FactorGrade.CAPABILITIES ||
      grade === FactorGrade.GPA_CAPABILITIES
    ) {
      return <IconCircleHalf2 size={75} color={theme.colors.orange[9]} />;
    }
    if (
      grade === FactorGrade.GROWTH_ZONES ||
      grade === FactorGrade.GPA_GROWTH_ZONES
    ) {
      return <IconStairs size={75} color={theme.colors.red[9]} />;
    }

    return <></>;
  }, [grade, theme.colors.orange, theme.colors.red]);

  return (
    <Stack>
      <Title order={3}>
        {FactorGradeTitle[grade]}
        {data && (
          <Text
            span
            color={FactorGradeColor[grade]}
            style={{ fontFamily: "Alegreya, serif", fontWeight: 500 }}
          >
            {` ${data.length}`}
          </Text>
        )}
      </Title>
      <Card withBorder radius="xs">
        <Card.Section
          bg={cardBackgroundColor}
          h={180}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {Icon}
        </Card.Section>
        {showSubtitle && (
          <Text mt="md" color="dimmed">
            {FactorGradeSubtitle[grade]}
          </Text>
        )}
        {data && (
          <SimpleGrid mt="md" cols={{ base: 3, md: 2, xs: 1 }}>
            {data.map(({ id, name, description }) => (
              <Group
                key={id}
                onClick={() => navigate(`/factors/${id}`)}
                style={{
                  cursor: "pointer",
                }}
              >
                <Text fw="bold" color={FactorGradeColor[grade]}>
                  •
                </Text>
                <Tooltip
                  label={description}
                  transitionProps={{ duration: 200 }}
                  maw={500}
                  opacity={0.5}
                  multiline
                  withArrow
                >
                  <Text>{name}</Text>
                </Tooltip>
              </Group>
            ))}
          </SimpleGrid>
        )}
      </Card>
    </Stack>
  );
};

export default AssessmentResultCard;
