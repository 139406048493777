import React from "react";

import { Text, Image } from "@mantine/core";
import IncomeLadder from "assets/images/questions/income-ladder.png";
import RadioSelect from "UI/RadioSelect";

interface ILadderInput {
  label: string;
  value: string;
  onChange: (value: string) => void;
  data: { label: string; value: string }[];
}
const LadderInput = ({
  label,
  value,
  onChange,
  data,
}: ILadderInput): JSX.Element => {
  return (
    <>
      <Image maw="200px" src={IncomeLadder} alt="income ladder" />
      <Text style={{ whiteSpace: "pre-line" }} mb="md">
        {label}
      </Text>
      <RadioSelect
        value={value}
        onChange={(answer) => onChange(answer)}
        options={data}
      />
    </>
  );
};

export default LadderInput;
