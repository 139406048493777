import React, { useEffect, useMemo, useState } from "react";

import {
  Button,
  Stack,
  Group,
  Pagination,
  Loader,
  Modal,
  ModalProps,
  Title,
} from "@mantine/core";
import useDemographicProfileProgress from "hooks/demographic-profile/useDemographicProfileProgress";
import useDemographicProfileQuestions from "hooks/demographic-profile/useDemographicProfileQuestions";
import useDemographicProfileResults from "hooks/demographic-profile/useDemographicProfileResults";
import useSaveDemographicProfileAnswers from "hooks/demographic-profile/useSaveDemographicProfileAnswers";
import useSubmitDemographicProfile from "hooks/demographic-profile/useSubmitDemographicProfile";
import { useAuth } from "providers/AuthProvider";
import { IAssessmentQuestion } from "types/api/assessmentTypes";
import { IDemographicProfileAnswers } from "types/api/demographicProfileTypes";
import { getFormattedDpResults } from "utils/assessmentHelper";
import {
  showErrorNotification,
  showSuccessNotification,
} from "utils/notifications";

import QuestionInputSwitch from "../components/QuestionInputSwitch";


const DemographicProfileDetailsModal = ({
  opened,
  onClose,
}: ModalProps): JSX.Element => {
  const { user } = useAuth();

  const [page, setPage] = useState(2);
  const [answers, setAnswers] = useState<IDemographicProfileAnswers>({});

  const {
    data: progressData,
    isLoading: isProgressLoading,
    refetch: refetchProgress,
  } = useDemographicProfileProgress({
    enabled: !!user,
  });

  const { data: resultsData, isLoading: isResultsLoading } =
    useDemographicProfileResults({ page, enabled: !!user });
  
  const { data: questionsData, isLoading: isQuestionsLoading } =
    useDemographicProfileQuestions({ page, enabled: !!user });

  const saveDemographicProfileAnswersMutation = useSaveDemographicProfileAnswers();
  const submitDemographicProfileMutation = useSubmitDemographicProfile();

  const [initialAnswersCount, setInitialAnswersCount] = useState(0);

  useEffect(() => {
    if (resultsData) {
      const demographicResultsFormatted = getFormattedDpResults(
        resultsData
      );

      setInitialAnswersCount(Object.keys(demographicResultsFormatted).length);
      setAnswers({
        ...demographicResultsFormatted,
      });
    }
  }, [resultsData]);

  const handleSubmit = () => {
    const handleSaveAnswersSuccess = () => {
      submitDemographicProfileMutation.mutate(undefined, {
        onSuccess: () => {
          showSuccessNotification(
              "Submit is successful",
              "Demographic questions is submitted successfully"
          );
          onClose();
        },
      });
    };

    const handleMutationError = () => {
      showErrorNotification("Submit Failed", "Something went wrong, please try again.");
    };

    saveDemographicProfileAnswersMutation.mutate(answers, {
      onError: handleMutationError,
      onSuccess: handleSaveAnswersSuccess,
    });
  };


  const handleChangePage = async (pageNumber: number): Promise<void> => {
    try {
      await saveDemographicProfileAnswersMutation.mutate(answers);
      await refetchProgress();
      if (!pageNumber) {
        setPage((currentPage) => currentPage + 1);
      } else {
        setPage(pageNumber + 1);
      }
    } catch (e) {
      showErrorNotification("Answers save is failed");
    }
  };

  const handleClose = (): void => {
    saveDemographicProfileAnswersMutation.mutate(answers);
    onClose();
  };

  const isSubmitDisabled = useMemo(() => {
    if (!progressData) {
      return true;
    }
    const answersCount =
      progressData?.answered_count -
      initialAnswersCount +
      Object.keys(answers).length;

    return progressData.questions_count !== answersCount;
  }, [answers, initialAnswersCount, progressData]);



  const totalPages = questionsData?.count
    ? Math.ceil(questionsData?.count / 10)
    : 1;

  return (
    <Modal
      size="lg"
      opened={opened}
      onClose={handleClose}
      title={
        <Title order={2} style={{ align: "center" }}>
          Demographic Profiling Questions
        </Title>
      }
    >
      <Stack>
        {(isQuestionsLoading || isProgressLoading || isResultsLoading) && (
          <Group justify="center">
            <Loader />
          </Group>
        )}
        {questionsData && progressData && resultsData && (
          <>
            <Stack>
              {questionsData?.results.map(
                (question: IAssessmentQuestion) => (
                  <QuestionInputSwitch
                    key={question.id}
                    question={question}
                    answers={answers}
                    setAnswers={setAnswers}
                  />
                )
              )}
            </Stack>
            <Button
              mt="lg"
              onClick={handleSubmit}
              disabled={isSubmitDisabled}
              fullWidth
            >
              Submit
            </Button>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Pagination
                defaultValue={page - 1}
                total={totalPages - 1}
                onChange={handleChangePage}
                disabled={
                  saveDemographicProfileAnswersMutation.isPending || submitDemographicProfileMutation.isPending
                }
              />
            </div>
          </>
        )}
      </Stack>
    </Modal>
  );
};

export default DemographicProfileDetailsModal;
