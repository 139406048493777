import React from "react";

import AssessmentNavigationPanel from "components/assessment/AssessmentNavigationPanel";
import useFactor from "hooks/factors/useFactor";
import { useParams } from "react-router-dom";
import { IAssessmentProgress } from "types/api/question";

const FactorAssessmentNavigationPanel = ({
  progressData,
}: {
  progressData: IAssessmentProgress;
}): JSX.Element => {
  const { factorId } = useParams();

  const { data } = useFactor(factorId as string);

  return (
    <AssessmentNavigationPanel
      title={data?.name || ""}
      goBackRoute="/"
      questionsCountData={{
        answered: progressData.answered_count,
        total: progressData.questions_count,
      }}
    />
  );
};

export default FactorAssessmentNavigationPanel;
