import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { authorizedAxiosInstance } from "utils/axios-instance";

interface GpaPausedPageResponse {
  resumed_page: boolean | number
}

export default function useGpaPausedPage({
  enabled,
}: {
  enabled: boolean;
}): UseQueryResult<GpaPausedPageResponse> {
  const queryFn = (): Promise<GpaPausedPageResponse> => {
    return authorizedAxiosInstance.get("/gpa/continue");
  };

  return useQuery({
    queryKey: ["gpaPausedPage"],
    queryFn,
    enabled,
  });
}
