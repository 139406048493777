import React, { useEffect, useState } from "react";

import { ActionIcon, Group, Tooltip } from "@mantine/core";
import { usePagination } from "@mantine/hooks";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons";

const Pagination = ({
  page,
  total,
  onChange,
  isNextDisabled = false,
}: {
  page: number;
  total: number;
  onChange: (page: number) => void;
  isNextDisabled?: boolean;
}): JSX.Element => {
  const { next, previous } = usePagination({ page, total, onChange });

  const isFirstPage = page === 1;
  const [opened, setOpened] = useState(isFirstPage);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (page === 1 && opened) {
      timeoutId = setTimeout(() => setOpened(false), 1000 * 20);
    }

    return () => clearTimeout(timeoutId);
  }, [page]);

  return (
    <Group justify="center" gap="xs">
      <ActionIcon
        radius="sm"
        size="lg"
        variant="default"
        color="cyan"
        onClick={previous}
        disabled={page === 1}
      >
        <IconChevronLeft size={16} />
      </ActionIcon>
      {page !== 1 && (
        <ActionIcon
          radius="sm"
          size="lg"
          variant="default"
          color="cyan"
          onClick={previous}
        >
          {page - 1}
        </ActionIcon>
      )}
      <Tooltip label={`Total of ${total} pages`} opened={opened} withArrow>
        <ActionIcon
          radius="sm"
          size="lg"
          variant="filled"
          color="cyan"
          onMouseOut={() => setOpened(false)}
          onMouseOver={() => setOpened(true)}
        >
          {page}
        </ActionIcon>
      </Tooltip>
      {page !== total && (
        <ActionIcon
          disabled={isNextDisabled}
          radius="sm"
          size="lg"
          variant="default"
          color="cyan"
          onClick={next}
        >
          {page + 1}
        </ActionIcon>
      )}
      <ActionIcon
        radius="sm"
        size="lg"
        variant="default"
        color="cyan"
        onClick={next}
        disabled={page === total || isNextDisabled}
      >
        <IconChevronRight size={16} />
      </ActionIcon>
    </Group>
  );
};

export default Pagination;
