import {
  IAssessmentValue,
  IPaginatedAssessmentQuestions,
} from "types/api/assessmentTypes";
import {
  IDemographicProfileAnswers,
  IDemographicResultTypes,
} from "types/api/demographicProfileTypes";
import { IAssessmentProgress } from "types/api/question";

export const getAssessmentsValueFromData = (
  data: IPaginatedAssessmentQuestions
): IAssessmentValue =>
  data.results.reduce((acc: IAssessmentValue, question) => {
    const answeredOptionChoices = question.answered_option_choice;
    const optionChoice =
      Array.isArray(answeredOptionChoices) && answeredOptionChoices[0];
    if (optionChoice) {
      acc[question.id] = optionChoice.id;
    }
    return acc;
  }, {});

export const getActualAssessmentProgressData = (
  progress: IAssessmentProgress,
  newValueCount: number
): IAssessmentProgress => {
  return {
    answered_count: progress.answered_count + newValueCount,
    questions_count: progress.questions_count,
  };
};

export const getFormattedDpResults = (
  demographicResults: IDemographicResultTypes[]
): IDemographicProfileAnswers =>
  demographicResults.reduce(
    (formatted: any, result: IDemographicResultTypes) => {
      formatted[result.question_id] = result.option_choice_id;
      return formatted;
    },
    {}
  );
