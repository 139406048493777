import React, { useMemo } from "react";

import { Card, List, Text, Title, useMantineTheme } from "@mantine/core";
import { IconCircleHalf2, IconStairs, IconTriangle } from "@tabler/icons";
import { Link, useNavigate } from "react-router-dom";

const AssessmentResultCard = ({
  title,
  description,
  factors,
}: {
  title: string;
  description: string;
  factors?: { id: number; name: string }[];
}): JSX.Element => {
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const color = useMemo((): string => {
    if (title === "Strengths") {
      return "green";
    }
    if (title === "Capabilities") {
      return "orange";
    }
    if (title === "Growth Zones") {
      return "red";
    }

    return "";
  }, [title]);

  const Icon = useMemo((): JSX.Element => {
    if (title === "Strengths") {
      return <IconTriangle size={75} color="green" />;
    }
    if (title === "Capabilities") {
      return <IconCircleHalf2 size={75} color={theme.colors.orange[9]} />;
    }
    if (title === "Growth Zones") {
      return <IconStairs size={75} color={theme.colors.red[9]} />;
    }

    return <></>;
  }, [theme.colors.orange, theme.colors.red, title]);

  return (
    <Card withBorder radius="xs">
      <Card.Section
        bg={color === "green" ? theme.colors.lime[5] : color}
        h={180}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {Icon}
      </Card.Section>
      <Title order={3} mt="md">
        {title}
        {factors && (
          <Text
            span
            color={color}
            style={{ fontFamily: "Alegreya, serif", fontWeight: 500 }}
          >
            {` ${factors.length}`}
          </Text>
        )}
      </Title>
      <Text color="dimmed">{description}</Text>
      {factors && (
        <>
          <List
            my="md"
            spacing={4}
            icon={
              <Text fw="bold" color={color}>
                •
              </Text>
            }
          >
            {factors.slice(0, 3).map(({ id, name }) => (
              <List.Item
                key={id}
                onClick={() => navigate(`/factors/${id}`)}
                style={{
                  cursor: "pointer",
                }}
              >
                {name}
              </List.Item>
            ))}
          </List>
          {factors.length > 3 && (
            <Text mt="xl" fw={500} component={Link} to="my-growth-profile">
              See more
            </Text>
          )}
        </>
      )}
    </Card>
  );
};

export default AssessmentResultCard;
