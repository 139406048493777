import { useQuery, UseQueryResult } from "@tanstack/react-query";
import {AxiosError, AxiosResponse} from "axios";
import { authorizedAxiosInstance } from "utils/axios-instance";

export default function useTestingSwitches(): UseQueryResult<
  { is_testing?: boolean }, AxiosError
> {
  const queryFn = (): Promise<AxiosResponse<{ is_testing?: boolean }>> => {
    return authorizedAxiosInstance.get(`/switches/testing`);
  };

  return useQuery({
    queryFn,
    queryKey: ["switches"],
  });
}
