import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { IPaginatedAssessmentQuestions } from "types/api/assessmentTypes";
import { IPaginatedRequest } from "types/api/pagination";
import { authorizedAxiosInstance } from "utils/axios-instance";
import {AxiosError} from "axios";

export default function useGpaQuestions(
  params: IPaginatedRequest
): UseQueryResult<IPaginatedAssessmentQuestions, AxiosError> {
  const queryFn = (): Promise<IPaginatedAssessmentQuestions> => {
    return authorizedAxiosInstance.get("/gpa", {
      params,
    });
  };

  return useQuery({
    queryKey: ["gpaQuestions", params],
    queryFn,
  });
}
