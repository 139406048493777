import React from "react";

import { Group } from "@mantine/core";
import { AssessmentCarouselValue } from "components/assessment/AssessmentCarousel";
import ContentContainer from "components/ContentContainer";
import { IEmblaCarouselProps } from "components/EmblaCarousel";
import useSaveFactorAnswers from "hooks/factors/useSaveFactorAnswers";
import { useNavigate, useParams } from "react-router-dom";
import { IPaginatedAssessmentQuestions } from "types/api/assessmentTypes";
import Pagination from "UI/Pagination";
import { showErrorNotification } from "utils/notifications";

const FactorAssessmentPagination = ({
  value,
  emblaApi,
  totalPage,
  questionsData,
}: {
  value: AssessmentCarouselValue;
  emblaApi: IEmblaCarouselProps["emblaApi"];
  totalPage: number;
  questionsData: IPaginatedAssessmentQuestions;
}): JSX.Element => {
  const { factorId, page } = useParams();
  const navigate = useNavigate();

  const saveFactorAnswersMutation = useSaveFactorAnswers(factorId as string);

  const isAllQuestionsAnswered =
    Object.keys(value).length >= questionsData.results.length;

  const handleSaveAnswers = (): void => {
    saveFactorAnswersMutation.mutate(value, {
      onError: () => showErrorNotification("Answers save is failed"),
    });
  };

  const handleChangePage = (pageNumber: number): void => {
    const valueCount = Object.values(value).length;
    if (valueCount > 0) {
      handleSaveAnswers();
    }

    if (emblaApi) {
      emblaApi.scrollTo(0);
    }
    navigate(`/factors/${factorId}/assessment/${pageNumber}`);
  };

  return (
    <ContentContainer>
      <Group justify="center" mb="lg">
        <Pagination
          page={Number(page)}
          total={totalPage}
          onChange={handleChangePage}
          isNextDisabled={!isAllQuestionsAnswered}
        />
      </Group>
    </ContentContainer>
  );
};

export default FactorAssessmentPagination;
