import React from "react";

import {
  Paper,
  Title,
  Text,
  TextInput,
  Button,
  Container,
  Group,
  Anchor,
  Center,
  Box,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconArrowLeft } from "@tabler/icons";
import useForgotPassword from "hooks/auth/useForgotPassword";
import {
  showErrorNotification,
  showSuccessNotification,
} from "utils/notifications";

import AuthLayout from "./components/AuthLayout";

interface FormValues {
  email: string;
}

const ForgotPasswordPage = (): JSX.Element => {
  const forgotPasswordMutation = useForgotPassword()

  const form = useForm<FormValues>({
    initialValues: {
      email: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const handleSubmit = (formValues: FormValues): void => {
    forgotPasswordMutation.mutate(formValues, {
      onError: () => {
        showErrorNotification("Error", "Something went wrong!")
      },
      onSuccess: () => {
        showSuccessNotification(
          "Success",
          "Check out your email, we sent recovery message!"
        )
      }
    })
  };

  return (
    <AuthLayout>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Container size={460} my={50}>
          <Paper shadow="xl" p={30} mt={30} radius="md">
            <Title fw={600}>Forgot your password?</Title>
            <Text
              color="dimmed"
              size="md"
              mt="lg"
              mb="xl"
              style={{ align: "center" }}
            >
              Enter the email address associated with your account and we&apos;ll
              send you a link to reset your password.
            </Text>
            <TextInput
              label="Your email"
              placeholder="your@mail.com"
              required
              {...form.getInputProps("email")}
            />
            <Group justify="space-between" mt="lg">
              <Anchor href="/login" size="sm" fw={600}>
                <Center inline>
                  <IconArrowLeft size={12} stroke={1.5} />
                  <Box ml={5}>Back to login page</Box>
                </Center>
              </Anchor>
              <Button type="submit" loading={forgotPasswordMutation.isPending}>
                Send link
              </Button>
            </Group>
          </Paper>
        </Container>
      </form>
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
