import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { IAssessmentProgress } from "types/api/question";
import { authorizedAxiosInstance } from "utils/axios-instance";

export default function useFactorProgress(
  factorId: string
): UseQueryResult<IAssessmentProgress> {
  const queryFn = (): Promise<AxiosResponse<IAssessmentProgress>> => {
    return authorizedAxiosInstance.get(`/factors/${factorId}/progress/`);
  };

  return useQuery({
    queryFn,
    queryKey: ["factorProgress", factorId],
  });
}
