import React from "react";

import FacebookLogin from "@greatsumini/react-facebook-login";
import { Stack } from "@mantine/core";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { useAuth } from "providers/AuthProvider";
import { decodeToken } from "react-jwt";
import { useSearchParams } from "react-router-dom";
import { ISessionAuthTokens } from "types/api/authTypes";
import { facebookAppId } from "utils/constants";
import { showErrorNotification } from "utils/notifications";

import FacebookButton from "./FacebookButton";

const SocialButtons = (): JSX.Element => {
  const { loginWithGoogle, loginWithFacebook } = useAuth();

  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect");

  const handleGoogleLoginFailure = (): void => {
    showErrorNotification("Error", "Google authentication failed");
  };
  const handleGoogleLogin = (response: CredentialResponse): void => {
    if (response?.credential) {
      const profileData: {
        email: string;
        family_name: string;
        given_name: string;
        sub: string;
      } | null = decodeToken(response.credential);

      if (profileData) {
        loginWithGoogle({
          email: profileData.email,
          google_id: profileData.sub,
          first_name: profileData.given_name,
          last_name: profileData.family_name,
        }).then(({ accessToken, csrfToken }: ISessionAuthTokens) => {
          if (redirectUrl) {
            return window.location.replace(
              `${redirectUrl}?access=${accessToken}?csrf=${csrfToken}`
            );
          }
          let url = "select-subscription";
          if (redirectUrl) {
            url += `?redirect_uri=${redirectUrl}`;
          }
          return window.location.replace(url);
        });
      }
    }
  };

  const handleFacebookLoginFailure = (): void =>
    showErrorNotification("Error", "Facebook authentication failed");

  const handleFacebookLogin = (response: any): void => {
    if (
      response?.email &&
      response?.id &&
      response?.first_name &&
      response?.last_name
    ) {
      loginWithFacebook({ ...response, facebook_id: response?.id }).then(
        ({ accessToken, csrfToken }: ISessionAuthTokens) => {
          if (redirectUrl) {
            return window.location.replace(
              `${redirectUrl}?access=${accessToken}?csrf=${csrfToken}`
            );
          }

          let url = "select-subscription";
          if (redirectUrl) {
            url += `?redirect_uri=${redirectUrl}`;
          }
          return window.location.replace(url);

        }
      );
    } else {
      handleFacebookLoginFailure();
    }
  };

  return (
    <Stack>
      <GoogleLogin
        onSuccess={handleGoogleLogin}
        onError={handleGoogleLoginFailure}
        size="medium"
        width="328"
      />
      {/* TODO Facebook needs to be configured and verified before it will be enabled */}
      <FacebookLogin
        appId={facebookAppId}
        onFail={handleFacebookLoginFailure}
        onProfileSuccess={handleFacebookLogin}
        fields="id,first_name,last_name,email"
        render={({ onClick }) => (
          <FacebookButton label="Sign in with Facebook" onClick={onClick} />
        )}
      />
    </Stack>
  );
};

export default SocialButtons;
