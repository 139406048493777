import React from "react";

import { Card, Stack } from "@mantine/core";
import ContentContainer from "components/ContentContainer";
import LoadingOverlay from "components/LoadingOverlay";
import useGrowthProfileResults from "hooks/gpa/useGrowthProfileResults";
import FullGrowthProfileAssessment from "modules/factors/FullGrowthProfileAssessment";
import { useAuth } from "providers/AuthProvider";

import AllFactors from "./modules/AllFactors";
import AssessmentAbout from "./modules/AssessmentAbout";
import ContributeToScience from "./modules/ContributeToScience";
import FactorAssessmentsResults from "./modules/FactorAssessmentsResults";
import GrowthProfileAssessmentResults from "./modules/GrowthProfileAssessmentResults";

const MyGrowthProfilePage = (): JSX.Element => {
  const { user } = useAuth();
  const isPaid = user?.is_paid;
  const isGpaPassed = user?.gpa_test;

  const { data, isLoading } = useGrowthProfileResults({
    enabled: isPaid,
  });

  const isFactorsAssessmentsVisible =
    !!data &&
    [...data?.strength, ...data?.opportunity, ...data?.growth]
      .length > 0;

  const isGpaVisible =
    isGpaPassed &&
    !!data &&
    [
      ...data?.strength_gpa,
      ...data?.opportunity_gpa,
      ...data?.growth_gpa,
    ].length > 0;

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      <ContentContainer>
        <Stack gap="xl">
          <AssessmentAbout />
          {!isGpaVisible && (
            <Card p={0} withBorder>
              <FullGrowthProfileAssessment />
            </Card>
          )}
          {isFactorsAssessmentsVisible && (
            <FactorAssessmentsResults data={data} />
          )}
          <ContributeToScience />
          {isGpaVisible && <GrowthProfileAssessmentResults data={data} />}
          <AllFactors />
        </Stack>
      </ContentContainer>
    </>
  );
};

export default MyGrowthProfilePage;
