import React from "react";

import {
  Badge,
  Divider,
  Group,
  List,
  Overlay,
  Paper,
  SimpleGrid,
  Text,
  Title,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import {
  FactorBadgeColor,
  FactorGrade,
  FactorGradeColor,
  FactorGradeTitle,
} from "modules/factors/utils/enums";
import { IFactor } from "types/api/factorTypes";

const SubFactors = ({
  data,
  showPercentage,
  isFactorAssessmentTaken,
}: {
  data: IFactor[];
  showPercentage: boolean;
  isFactorAssessmentTaken: boolean;
}): JSX.Element => {
  const isBlurred = !isFactorAssessmentTaken;

  const theme = useMantineTheme()

  const factors = data.map((factor) => {
    return (
      <List key={factor.id} spacing="xs" mt="-sm">
        <List.Item icon={<>{null}</>}>
          <Tooltip
            key={factor.name}
            label={FactorGradeTitle[factor.grade || FactorGrade.NOT_TAKEN]}
          >
            <Badge
              color={FactorGradeColor[factor.grade || FactorGrade.NOT_TAKEN]}
              size="lg"
              radius="xs"
              variant="dot"
              style={{
                root: {
                  textTransform: "capitalize",
                  height: "fit-content !important",
                },
                inner: {
                  whiteSpace: "inherit",
                  height: "fit-content !important",
                },
              }}
            >
              {showPercentage
                ? `${factor.score_percent}% - ${factor.name}`
                : factor.name}
            </Badge>
          </Tooltip>
          {!!factor.sub_factors && (
            <List listStyleType="disc" withPadding>
              {factor.sub_factors.map(({ grade, name }) => (
                <List.Item key={name}>
                  <Tooltip
                    key={name}
                    label={FactorGradeTitle[grade || FactorGrade.NOT_TAKEN]}
                  >
                    <Badge
                      color={FactorBadgeColor[grade || FactorGrade.NOT_TAKEN]}
                      size="md"
                      radius="xs"
                      style={{ root: { textTransform: "capitalize" } }}
                    >
                      {name}
                    </Badge>
                  </Tooltip>
                </List.Item>
              ))}
            </List>
          )}
        </List.Item>
      </List>
    );
  });

  return (
    <Paper radius="md" withBorder p="lg">
      <Group justify="space-between">
        <Title order={2}>Subfactors</Title>
        <Group>
          <Badge variant="dot" color="green">
            Strengths
          </Badge>
          <Badge variant="dot" color="orange">
            Capabilities
          </Badge>
          <Badge variant="dot" color="darkRed">
            Growth Zones
          </Badge>
        </Group>
      </Group>
      <Text mt="xs" color="dimmed">
        Subfactors are a way of zooming in and considering segments of a given
        factor in more detail. They are also the scientific measures that help
        researchers understand more about this factor of well-being.
      </Text>
      <Divider my="md" />
      <div style={{ position: "relative" }}>
        {isBlurred && <Overlay m='-lg' opacity={1} blur={3} color={theme.colors.gray[0]} backgroundOpacity={0.2} />}
        <SimpleGrid
          mb="xl"
          spacing="xs"
          verticalSpacing="xs"
          cols={{ base: 3, md: 2, xs: 1}}
        >
          {factors}
        </SimpleGrid>
      </div>
    </Paper>
  );
};

export default SubFactors;
