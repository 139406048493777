import React from "react";

import { Space, useMantineTheme } from "@mantine/core";
import ContentContainer from "components/ContentContainer";
import FullGrowthProfileAssessment from "modules/factors/FullGrowthProfileAssessment";

const FullGrowthProfileCard = (): JSX.Element => {
  const theme = useMantineTheme();

  return (
    <>
      <ContentContainer mt={`-calc(${theme.spacing.lg} * 5)`}>
        <FullGrowthProfileAssessment />
      </ContentContainer>
      <Space my={theme.spacing.xl} />
    </>
  );
};

export default FullGrowthProfileCard;
