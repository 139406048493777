import React from "react";

import { Center, Group, UnstyledButton, Text } from "@mantine/core";
import { IconChevronDown, IconChevronUp, IconSelector } from "@tabler/icons";

import classes from "./Th.module.scss";

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
}

const Th = ({ children, reversed, sorted, onSort }: ThProps): JSX.Element => {
  const ReservedIcon = reversed ? IconChevronUp : IconChevronDown;
  const Icon = sorted ? ReservedIcon : IconSelector;

  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control} px="xs">
        <Group justify="space-between">
          <Text fw={500}>{children}</Text>
          <Center className={classes.icon}>
            <Icon size={14} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
};

export default Th;
