import React from "react";

import { Group, Radio, RadioGroupProps, RadioProps } from "@mantine/core";

const RadioSelect = ({
  options,
  radioProps,
  ...radioGroupProps
}: Omit<RadioGroupProps, "children"> & {
  options: { label: string; value: string }[];
  radioProps?: RadioProps;
}): JSX.Element => {
  return (
    <Radio.Group {...radioGroupProps}>
      <Group>
        {options.map(({ value, label }) => (
          <Radio
            key={value}
            color="gray"
            {...radioProps}
            label={label}
            value={value}
            style={{
              radio: { cursor: "pointer" },
              label: { cursor: "pointer" },
            }}
          />
        ))}
      </Group>
    </Radio.Group>
  );
};

export default RadioSelect;
