import React from "react";

import { Group, Highlight, Stack, Text, Title } from "@mantine/core";
import BackButton from "components/BackButton";

const AssessmentAbout = (): JSX.Element => {
  return (
    <Stack my="lg">
      <Group>
        <BackButton label="Home" to="/" />
      </Group>
      <Title mt="lg">My Growth Profile</Title>
      <Text size="lg">
        Below is an overview of your results.
        <br />
        The first set is from the individual factor assessments you&apos;ve taken.
        <br />
        <Highlight highlight="Take Assessment">
          You can take a Factor Assessment by clicking a factor and then Take
          Assessment on that factor&apos;s page.
        </Highlight>
        These tests contain more questions than the GPA/overview assessment,
        letting you measure a factor with more accuracy. And after taking one,
        you can see subfactor scores on that factor&apos;s page too.
      </Text>
    </Stack>
  );
};

export default AssessmentAbout;
