import React from "react";

import { Button, Group, Skeleton } from "@mantine/core";
import CustomMediaQuery from "components/CustomMediaQuery";
import { useAuth } from "providers/AuthProvider";
import { Link } from "react-router-dom";

const AuthenticationButtons = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  const { isInitialized } = useAuth();

  return (
    <div className={className}>
      <CustomMediaQuery smallerThan="xs">
        <Group>
          <Skeleton visible={!isInitialized}>
            <Group>
              <Button component={Link} to="/login" variant="default">
                Log in
              </Button>
              <Button component={Link} to="/register">
                Sign up
              </Button>
            </Group>
          </Skeleton>
        </Group>
      </CustomMediaQuery>
      <CustomMediaQuery largerThan="xs">
        <Group justify="center" grow pb="xl" px="md">
          <Button variant="default" component={Link} to="/login">
            Log in
          </Button>
          <Button component={Link} to="/register">
            Sign up
          </Button>
        </Group>
      </CustomMediaQuery>
    </div>
  );
};

export default AuthenticationButtons;
