import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { authorizedAxiosInstance } from "utils/axios-instance";

export default function useSubmitGpaAssessment(): UseMutationResult<
  unknown,
  AxiosError,
  void
> {
  const mutationFn = () => {
    return authorizedAxiosInstance.post("/gpa/submit");
  };

  return useMutation({
    mutationFn,
  });
}
