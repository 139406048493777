import React, { useEffect, useState } from "react";

import { Stack } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import LoadingOverlay from "components/LoadingOverlay";
import useEmblaCarousel from "embla-carousel-react";
import useFactorAssessmentHistory from "hooks/factors/useFactorAssessmentHistory";
import useFactorProgress from "hooks/factors/useFactorProgress";
import useDpIntroAnswered from "hooks/useDpIntroAnswered";
import DemographicProfileIntroModal from "layout/modules/Header/modules/DemographicProfileIntroModal";
import { useParams } from "react-router-dom";
import { IAssessmentValue } from "types/api/assessmentTypes";
import {
  getActualAssessmentProgressData,
  getAssessmentsValueFromData,
} from "utils/assessmentHelper";

import FactorAssessmentCarousel from "./modules/FactorAssessmentCarousel";
import FactorAssessmentNavigationPanel from "./modules/FactorAssessmentNavigationPanel";
import FactorAssessmentPagination from "./modules/FactorAssessmentPagination";
import useFactorQuestions from "hooks/factors/useFactorQuestions";

const FactorAssessmentPage = (): JSX.Element => {
  const { page, factorId } = useParams();
  const { isDemoAnswered, refetch } = useDpIntroAnswered();

  const {
    data: progressData,
    isLoading: isFactorProgressLoading,
    isFetching: isProgressFetching,
  } = useFactorProgress(factorId as string);

  const {
    data: factorQuestionsData,
    isLoading: isFactorQuestionsLoading ,
    isSuccess: isFactorQuestionsSuccess ,
  } = useFactorQuestions({
    factorId: factorId as string,
    page: page as string,
  });

  const { data: assessmentHistoryData } = useFactorAssessmentHistory({
    factorId: factorId as string,
  });

  const [
    isDemoOpened,
    {
      open: openDemo,
      close: closeDemo
    }
  ] = useDisclosure(false);
  const [value, setValue] = useState<IAssessmentValue>({});
  const [initialValueCount, setInitialValueCount] = useState(0);

  const newValueCount = Object.keys(value).length - initialValueCount;

  const handleDemoClose = (): void => {
    refetch();
    closeDemo();
  };

  useEffect(() => {
    if (isFactorQuestionsSuccess && factorQuestionsData) {
      const newAnswersMap = getAssessmentsValueFromData(factorQuestionsData);
      setValue(newAnswersMap);
      setInitialValueCount(Object.keys(newAnswersMap).length);
    }
  }, [isFactorQuestionsSuccess, factorQuestionsData]);

  useEffect(() => {
    if (
      !isDemoAnswered &&
      assessmentHistoryData &&
      assessmentHistoryData?.length >= 3
    ) {
      openDemo();
    } else {
      closeDemo();
    }
  }, [assessmentHistoryData, isDemoAnswered]);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    axis: "y",
    loop: false,
    draggable: false,
  });

  return (
    <>
      <Stack pos="relative" mih="100%" mb="lg">
        <LoadingOverlay
          visible={
            isFactorQuestionsLoading ||
            isFactorProgressLoading ||
            isProgressFetching
          }
        />
        {factorQuestionsData && progressData && (
          <>
            <FactorAssessmentNavigationPanel
              progressData={getActualAssessmentProgressData(
                progressData,
                newValueCount
              )}
            />
            <FactorAssessmentCarousel
              emblaApi={emblaApi}
              emblaRef={emblaRef}
              value={value}
              setValue={setValue}
              progressData={getActualAssessmentProgressData(
                progressData,
                newValueCount
              )}
              questionsData={factorQuestionsData}
              isModalOpen={isDemoOpened}
            />
            <FactorAssessmentPagination
              emblaApi={emblaApi}
              value={value}
              totalPage={Math.ceil(factorQuestionsData?.count / 10)}
              questionsData={factorQuestionsData}
            />
          </>
        )}
      </Stack>
      <DemographicProfileIntroModal
        opened={isDemoOpened}
        onClose={handleDemoClose}
      />
    </>
  );
};

export default FactorAssessmentPage;
