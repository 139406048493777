import React from "react";

import { Avatar, Badge, AvatarProps, BadgeProps } from "@mantine/core";
import { IconHourglassLow } from "@tabler/icons";

const MinutesBadge = ({
  value,
  iconProps,
  ...badgeProps
}: BadgeProps & {
  value: number | string;
  iconProps?: AvatarProps;
}): JSX.Element => {
  return (
    <Badge
      {...badgeProps}
      style={{ paddingLeft: 0 }}
      radius="xl"
      variant="light"
      color="teal"
      leftSection={
        <Avatar color="teal" size={20} mr={0} {...iconProps}>
          <IconHourglassLow />
        </Avatar>
      }
    >
      ~ {value} minutes
    </Badge>
  );
};

export default MinutesBadge;
