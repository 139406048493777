import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IDemographicProfileAnswers } from "types/api/demographicProfileTypes";
import { authorizedAxiosInstance } from "utils/axios-instance";

export default function useSaveDemographicProfileAnswers(): UseMutationResult<
  unknown,
  AxiosError,
  IDemographicProfileAnswers
> {
  const mutationFn = (
    payload: IDemographicProfileAnswers
  ): Promise<unknown> => {
    return authorizedAxiosInstance.post("/dp/questions", payload);
  };

  return useMutation({
    mutationFn,
  });
}
