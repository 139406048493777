import { Navigate } from "react-router-dom";
import { useAuth } from '../../../providers/AuthProvider';

const SubscriptionPaymentSuccessPage = (): JSX.Element => {
  const { refresh } = useAuth();
  refresh();
  return <Navigate to="/profile" replace />;
};

export default SubscriptionPaymentSuccessPage;
