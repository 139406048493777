import React from "react";

import { Anchor, Stack, Text } from "@mantine/core";

const AssessmentMeasuring = (): JSX.Element => (
  <Stack>
    <Text>
      By using the Assessment Center, you can analyze up to 50 different primary
      factors of well-being. Each factor represents an aspect of life that
      serves greater well-being. Mostfactors contain subfactors as well, for
      more detailed insight and feedback. There are over 200 subfactors in
      total.
    </Text>
    <Text>
      Our feedback system is simple: take an assessment, and learn whether it is
      a Strength, a Capability, or a Growth Zone. For each factor, you can learn
      and practice more on AMeaningOfLife, returning to assess factors as they
      change, and enhancing your well-being through the individual skills that
      foster joy.
    </Text>
    <Text>
      You can get an overview of your factors by taking the single, long ‘Growth
      Profile Assessment,’ which measures every factor in small detail. You can
      assess a factor in more detail by clicking it and taking its individual
      assessment. After completing, you will also see subfactor results, if
      applicable, which are also graded as green/yellow/red.
    </Text>
    <Text>
      The full list of well-being factors is listed on the homepage of the app,
      and to understand their interplay more, you can poke around on
      AMeaningOfLife.org.
    </Text>
    <Text span>
      You can learn more about the Assessment Center and the benefits of
      membership{" "}
      <Anchor href="https://ameaningoflife.org/cornerstones-of-meaning/discovery/assessment-center/">
        here.
      </Anchor>
    </Text>
  </Stack>
);

export default AssessmentMeasuring;
