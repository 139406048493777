import React from "react";

import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AuthProvider } from "providers/AuthProvider";

import Router from "./Router";
import theme from "./styles/theme";
import { googleClientId } from "./utils/constants";
import "@mantine/notifications/styles.css";

const queryClient = new QueryClient();

function App(): JSX.Element {
  return (
    <MantineProvider theme={theme} defaultColorScheme="light" withCssVariables>
      <Notifications zIndex={99999} />

      <QueryClientProvider client={queryClient}>
        <ModalsProvider>
          <GoogleOAuthProvider clientId={googleClientId}>
            <AuthProvider>
              <Router />
            </AuthProvider>
          </GoogleOAuthProvider>
        </ModalsProvider>

        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </MantineProvider>
  );
}

export default App;
