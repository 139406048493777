import React, { useMemo } from "react";

import { Stack } from "@mantine/core";
import ContentContainer from "components/ContentContainer";
import { useParams } from "react-router-dom";
import HealthFactorResultBreakdown from "routes/public/FactorPage/+components/HealthFactorResultBreakdown";
import useFactorData from "routes/public/FactorPage/+hooks/useFactorData";

import ConnectedFactors from "./modules/ConnectedFactors";
import MainBlock from "./modules/MainBlock";
import PreviousResults from "./modules/PreviousResults";
import SubFactors from "./modules/SubFactors";

const FactorPage = (): JSX.Element => {
  const { factorId } = useParams();

  const {
    currentData,
    connectedFactors,
    sortedAssessmentHistory,
    progressData,
  } = useFactorData(factorId as string); // Custom hook that encapsulates all data fetching and sorting logic

  const showSubFactors = useMemo(
    () => currentData?.sub_factors && currentData.sub_factors.length > 0,
    [currentData]
  );
  const showPercentage = useMemo(
    () => currentData?.image === "attachment",
    [currentData]
  );
  const isFactorAssessmentTaken = useMemo(
    () => !!sortedAssessmentHistory.length,
    [sortedAssessmentHistory]
  );

  const HEALING_FACTOR_ID = "5";

  return (
    <ContentContainer py="md">
      <Stack>
        {currentData && (
          <>
            <MainBlock data={currentData} progressData={progressData} />
            {showSubFactors && (
              <SubFactors
                data={currentData.sub_factors}
                // Percentage showing in interpersonal attachment factor
                showPercentage={showPercentage}
                isFactorAssessmentTaken={isFactorAssessmentTaken}
              />
            )}
          </>
        )}
        {factorId === HEALING_FACTOR_ID &&
          sortedAssessmentHistory?.length > 0 && (
            <HealthFactorResultBreakdown />
          )}
        {connectedFactors && <ConnectedFactors data={connectedFactors} />}
        {sortedAssessmentHistory && sortedAssessmentHistory.length > 0 && (
          <PreviousResults data={sortedAssessmentHistory} />
        )}
      </Stack>
    </ContentContainer>
  );
};

export default FactorPage;
