export const sortBy = <T>(data: T[], fields: (keyof T)[]): T[] =>
  [...data].sort((a, b) => {
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      const result = a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0;
      if (result !== 0) return result;
    }
    return 0;
  });

export const getViewedDp = (): boolean => {
  try {
    const serializedValue = sessionStorage.getItem("viewedDp");
    if (!serializedValue) return false;

    const { expiry } = JSON.parse(serializedValue);
    const currentTime = new Date().getTime();
    return currentTime < expiry;
  } catch (error) {
    sessionStorage.removeItem("viewedDp");
    return false;
  }
};

export const updateViewedDp = (value: boolean): void => {
  const expiryTime = new Date().getTime() + 24 * 60 * 60 * 1000;
  const data = { viewed: value, expiry: expiryTime };
  const serializedValue = JSON.stringify(data);
  sessionStorage.setItem("viewedDp", serializedValue);
};
