import React, { useCallback } from "react";

import { Anchor, Button, Group, Tooltip } from "@mantine/core";
import AssessmentCarousel, {
  AssessmentCarouselValue,
} from "components/assessment/AssessmentCarousel";
import { IEmblaCarouselProps } from "components/EmblaCarousel";
import useSaveFactorAnswers from "hooks/factors/useSaveFactorAnswers";
import useSubmitFactorAssessment from "hooks/factors/useSubmitFactorAssessment";
import { useNavigate, useParams } from "react-router-dom";
import { IPaginatedAssessmentQuestions } from "types/api/assessmentTypes";
import { IAssessmentProgress } from "types/api/question";
import { InputType } from "types/inputTypes";
import {
  showErrorNotification,
  showSuccessNotification,
} from "utils/notifications";

const FactorAssessmentCarousel = ({
  emblaRef,
  emblaApi,
  questionsData,
  value,
  setValue,
  progressData,
  isModalOpen,
}: {
  emblaRef: IEmblaCarouselProps["emblaRef"];
  emblaApi: IEmblaCarouselProps["emblaApi"];
  questionsData: IPaginatedAssessmentQuestions;
  value: AssessmentCarouselValue;
  setValue: React.Dispatch<React.SetStateAction<AssessmentCarouselValue>>;
  progressData: IAssessmentProgress;
  isModalOpen: boolean;
}): JSX.Element => {
  const { factorId, page } = useParams();
  const navigate = useNavigate();

  const saveFactorAnswersMutation = useSaveFactorAnswers(factorId as string);
  const submitFactorAssessmentMutation = useSubmitFactorAssessment(
    factorId as string
  );

  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );

  const handleSelect = (id: number, _value: number | number[]): void => {
    setValue((prev) => ({ ...prev, [id]: _value }));

    const question = questionsData.results.find((q) => q.id === id);

    if (
      question &&
      question.input_type !== InputType.CHECKBOX &&
      emblaApi &&
      emblaApi.canScrollNext()
    ) {
      scrollNext();
    }
  };

  const isSubmitDisabled = !(
    progressData.questions_count === progressData.answered_count
  );
  const handleSubmit = () => {
    const handleSaveAnswersSuccess = () => {
      submitFactorAssessmentMutation.mutate(undefined, {
        onSuccess: () => {
          showSuccessNotification("Submit is successful");
          navigate(`/factors/${factorId}`, { replace: true });
          },
      });
    };

    const handleMutationError = () => {
      showErrorNotification(
        "Submit is failed",
        "Something went wrong, please try again"
      );
    };

    saveFactorAnswersMutation.mutate(value, {
      onError: handleMutationError,
      onSuccess: handleSaveAnswersSuccess,
    });
  };

  const valueCount = Object.keys(value).length;
  const totalQuestions = questionsData.results.length;

  const totalPage = Math.ceil(questionsData.count / 10);
  const isLastPage = Number(page) === totalPage;
  const isNextDisabled = valueCount < totalQuestions || isLastPage;

  const handleNextPage = useCallback(async () => {
    const isAllQuestionsAnswered =
      Object.keys(value).length >= questionsData.results.length;

    const answeredQuestions = Object.keys(value).map(Number);
    const firstUnansweredQuestionIndex = questionsData.results.findIndex(
      (question) => !answeredQuestions.includes(question.id)
    );

    if (!isAllQuestionsAnswered) {
      showErrorNotification(
        "Please answer all questions before proceeding to the next page."
      );
      if (emblaApi) {
        emblaApi.scrollTo(firstUnansweredQuestionIndex);
      }
      return;
    }
    if (!isNextDisabled && factorId) {
      if (valueCount > 0) {
        saveFactorAnswersMutation.mutate(value);
      }

      if (emblaApi) {
        emblaApi.scrollTo(0);
      }
      navigate(`/factors/${factorId}/assessment/${Number(page) + 1}`);
    }
  }, [emblaApi, navigate, value, questionsData]);

  const ActionButton = (
    <Group>
      <Anchor onClick={handleNextPage}>
        <Button disabled={isNextDisabled}>Next Page</Button>
      </Anchor>
      <Tooltip
        label={`To submit you need to give an answer to ${progressData.questions_count} questions`}
        disabled={!isSubmitDisabled}
      >
        <Group>
          <Button
            variant="light"
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
            loading={submitFactorAssessmentMutation.isPending}
          >
            Submit
          </Button>
        </Group>
      </Tooltip>
    </Group>
  );

  return (
    <AssessmentCarousel
      emblaRef={emblaRef}
      emblaApi={emblaApi}
      data={questionsData}
      value={value}
      onChange={handleSelect}
      ActionButton={ActionButton}
      isModalOpen={isModalOpen}
    />
  );
};

export default FactorAssessmentCarousel;
