import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { authorizedAxiosInstance } from "utils/axios-instance";

export default function useSubmitFactorAssessment(
  factorId: string
): UseMutationResult<unknown, AxiosError, void> {
  const queryClient = useQueryClient();

  const mutationFn = () => {
    return authorizedAxiosInstance.post(
      `/factors/${factorId}/assessment/submit`
    );
  };

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["factors"] });
    },
  });
}
