import React, { createContext, useContext } from "react";

import useTestingSwitches from "hooks/switches/useTestingSwitches";

const SwitchesContext = createContext({});

const SwitchesProvider = ({ children }: { children: React.ReactNode }): any => {
  const { data } = useTestingSwitches();

  const contextValue = {
    isTesting: data?.is_testing,
  };

  return (
    <SwitchesContext.Provider value={contextValue}>
      {children}
    </SwitchesContext.Provider>
  );
};

const useSwitches = (): any => {
  const context = useContext(SwitchesContext);
  if (context === undefined) {
    throw new Error("useSwitches must be used within a SwitchesProvider");
  }
  return context;
};

export { SwitchesProvider, useSwitches };
