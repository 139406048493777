import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { authorizedAxiosInstance } from "utils/axios-instance";

export default function useAcceptGpaInstructions(): UseMutationResult<
  unknown,
  AxiosError,
  void
> {
  const queryClient = useQueryClient();

  const mutationFn = () => {
    return authorizedAxiosInstance.post("/gpa/instruction");
  };

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["me"],
      });
    },
  });
}
