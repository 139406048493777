import React from "react";

import { Badge, Tooltip } from "@mantine/core";
import { MembershipType, Membership } from "types/api/paymentTypes";

const MembershipBadge = ({ type }: { type: MembershipType }): JSX.Element => {
  let color;

  if (type === Membership.UNPAID) {
    color = "orange";
  }
  if (type === Membership.LIFE) {
    color = "green";
  }
  if (type === Membership.YEAR) {
    color = "teal";
  }
  if (type === Membership.STANDARD) {
    color = "cyan";
  }

  return (
    <Tooltip label={`Your membership subscription type is "${type}"`}>
      <Badge color={color} size="xs" variant="outline">
        {type}
      </Badge>
    </Tooltip>
  );
};

export default MembershipBadge;
