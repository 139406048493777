import React from "react";

import { Paper, ScrollArea, Table, Title } from "@mantine/core";
import dayjs from "dayjs";
import FactorGradeResult from "modules/factors/components/FactorGradeResult";
import { FactorGrade, FactorGradeTitle } from "modules/factors/utils/enums";
import { IFactorAssessmentHistory } from "types/api/factorTypes";
import { sortBy } from "utils/functions";

const PreviousResults = ({
  data,
}: {
  data: IFactorAssessmentHistory[];
}): JSX.Element => {
  const getFormattedDate = (date: string): string =>
    date ? dayjs(date).format("D MMMM YYYY") : "-";
  const sortedPreviousResultsByVersion =
    !!data && sortBy(data, ["version", "completed_date"]).reverse();

  return (
    <Paper radius="md" withBorder p="lg">
      <Title order={2}>Previous results</Title>
      <ScrollArea>
        <Table
          verticalSpacing="md"
          fs="md"
          striped
          style={{ tableLayout: "fixed", minWidth: 700 }}
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Date</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            {sortedPreviousResultsByVersion.map(
              ({ name, completed_date, grade }) => (
                <tr key={name}>
                  <td>{name}</td>
                  <td>{getFormattedDate(completed_date)}</td>
                  <td>
                    <FactorGradeResult
                      title={FactorGradeTitle[grade || FactorGrade.NOT_TAKEN]}
                      fullWidth={false}
                    />
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </ScrollArea>
    </Paper>
  );
};

export default PreviousResults;
