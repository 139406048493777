import React from "react";

import { useDisclosure } from "@mantine/hooks";

import DesktopMenu from "./modules/DesktopHeader";
import MobileMenu from "./modules/MobileMenu";

const Header = (): JSX.Element => {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <DesktopMenu
        mobileMenuOpened={drawerOpened}
        toggleMobileMenu={toggleDrawer}
      />
      <MobileMenu opened={drawerOpened} onClose={closeDrawer} />
    </>
  );
};

export default Header;
