import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IFactorAssessmentHistory } from "types/api/factorTypes";
import { authorizedAxiosInstance } from "utils/axios-instance";

export default function useFactorAssessmentHistory({
  factorId,
  enabled = true,
}: {
  factorId: string;
  enabled?: boolean;
}): UseQueryResult<IFactorAssessmentHistory[], AxiosError> {
  const queryFn = (): Promise<IFactorAssessmentHistory[]> => {
    return authorizedAxiosInstance.get(
      `/factors/${factorId}/assessment/history`
    );
  };

  return useQuery({
    queryFn,
    queryKey: ["factorAssessmentHistory", factorId, enabled],
    enabled,
  });
}
