import React from "react";

import { SimpleGrid, Stack, Text, Title } from "@mantine/core";
import dayjs from "dayjs";
import { FactorGrade } from "modules/factors/utils/enums";
import { useAuth } from "providers/AuthProvider";
import { IGrowthProfileResponse } from "types/api/assessmentTypes";

import AssessmentResultCard from "../components/AssessmentResultCard";

const GrowthProfileAssessmentResults = ({
  data,
}: {
  data: IGrowthProfileResponse;
}): JSX.Element => {
  const { user } = useAuth();
  const gpaSubmittedDate = !!user && user.gpa_completed_date;

  return (
    <Stack my="lg">
      <div>
        <Title>Growth Profile Assessment Results</Title>
        <Title order={3} style={{ color: "cyan" }}>
          Taken: {dayjs(gpaSubmittedDate).format("D MMMM YYYY")}
        </Title>
      </div>
      <Text size="lg">
        These scores reflect your results from the 360-question GPA, an overview
        assessment that measures all of the factors in 5 questions each. The
        scores are less accurate than individual Factor Assessments but can give
        you a quicker overview of all the factors at once, applied to you.
      </Text>

      {data.strength_gpa.length > 0 && (
        <AssessmentResultCard
          grade={FactorGrade.GPA_STRENGTHS}
          data={data.strength_gpa}
        />
      )}
      <SimpleGrid mt="md" cols={{ base: 2, xl: 2, lg: 2, md: 1 }}>
        {data.opportunity_gpa.length > 0 && (
          <AssessmentResultCard
            grade={FactorGrade.GPA_CAPABILITIES}
            data={data.opportunity_gpa}
          />
        )}
        {!!data.growth_gpa.length && (
          <AssessmentResultCard
            grade={FactorGrade.GPA_GROWTH_ZONES}
            data={data.growth_gpa}
          />
        )}
      </SimpleGrid>
    </Stack>
  );
};

export default GrowthProfileAssessmentResults;
