import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { SignUpDto } from "providers/models/sign-up-dto";
import { IAuthTokensResponse } from "types/api/authTypes";
import axiosInstance from "utils/axios-instance";

function useRegister(): UseMutationResult<
  AxiosResponse<IAuthTokensResponse>,
  AxiosError<any>,
  SignUpDto
> {
  const queryClient = useQueryClient();

  const mutationFn = (payload: SignUpDto) => {
    return axiosInstance.post("/users/signup", payload);
  };

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
  });
}

export default useRegister;
