import React from "react";

import { UnstyledButton, Group, Avatar, Text } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons";
import { Link, LinkProps } from "react-router-dom";

import classes from "./UserProfileButton.module.scss";

const UserProfileButton = ({
  firstName,
  lastName,
  email,
  icon,
  ...unstyledButtonProps
}: Omit<LinkProps, "to"> & {
  firstName: string;
  lastName: string;
  email: string;
  icon?: React.ReactNode;
}): JSX.Element => {
  return (
    <UnstyledButton
      className={classes.user}
      component={Link}
      to="/profile"
      {...unstyledButtonProps}
    >
      <Group>
        <Avatar color="cyan" radius="xl">
          {firstName[0] + lastName[0]}
        </Avatar>

        <div style={{ flex: 1 }}>
          <Text size="sm" fw={500}>
            {`${firstName} ${lastName}`}
          </Text>

          <Text color="dimmed" size="xs">
            {email}
          </Text>
        </div>

        {icon || <IconChevronRight size={14} stroke={1.5} />}
      </Group>
    </UnstyledButton>
  );
};

export default UserProfileButton;
