import React from "react";

import useBem from "hooks/useBem";

const EmblaSlide = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { bemElement } = useBem("EmblaCarousel");
  return <div className={bemElement("slide")}>{children}</div>;
};

export default EmblaSlide;
