import axios, { AxiosInstance } from "axios";

import TokenHelper from "./tokenHelper";

export const baseUrl = `${process.env.REACT_APP_BACKEND_URL as string}`;

const axiosInstance = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});
export default axiosInstance;

export const authorizedAxiosInstance = ((): AxiosInstance => {
  const instance = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    }
  })

  instance.interceptors.response.use(response => response.data)
  instance.interceptors.request.use(config => {

    if (TokenHelper.hasTokens()) {
      const { accessToken, csrfToken } = TokenHelper.get();

      config.headers.Authorization = `Bearer ${accessToken}`;
      config.headers["X-CSRFToken"] = csrfToken;
    }

    return config
  })

  return instance
})();
